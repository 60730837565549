
import { httpCatalogService } from "../http-service";
import { Catalog, CatalogToCreate, Catalogs } from "../model/Catalog";

//Search
const getAll = async (
  search: string,
  catalogTypes: string,// 0 | 1 | 2,
  pageNumber: number,
  pageSize: number,
  orderBy: string,
  sortDirection: string
) => {
  const params = new URLSearchParams({
    search: search,
    catalogTypes: String(catalogTypes),
    pageNumber: String(pageNumber),
    pageSize: String(pageSize),
    orderBy: orderBy,
    sortDirection: sortDirection,
  });

  const data = await httpCatalogService.get<Catalogs>(`/catalog?${params}`);

  return data;
};

  const getById = async (id: string) => {
    const data = await httpCatalogService.get<Catalog>(
      `/catalog/${id}`
    );
  
    return data;
  };
 
  const create = async (catalog: CatalogToCreate) => {
    try {
      const params = JSON.stringify(catalog);
      
      const response = await httpCatalogService.post<CatalogToCreate>(
        `/catalog`,
        params,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200 || response.status === 202) {
        return { success: true };
      } else {
        throw new Error('Failed to update catalog'); // Throw an error for non-200 status codes
      }
    } catch (error:any) {
      console.error('Error updating catalog:', error?.response?.data?.detail);
      return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
    }
  };
  
  const update = async (catalog: Catalog) => {
    try {
      const params = JSON.stringify(catalog);
      console.log(params);
  
      const response = await httpCatalogService.put<Catalog>(
        `/catalog`,
        params,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        return { success: true };
      } else {
        throw new Error('Failed to update catalog'); // Throw an error for non-200 status codes
      }
    } catch (error:any) {
      console.error('Error updating catalog:', error?.response?.data);
      return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
    }
  };
  
  const deleteAll = async (catalogs: Catalog[]) => {
    try {
      const deletePromises = catalogs.map(async (catalog: Catalog) => {
         
        const response = await httpCatalogService.delete<Catalog>(`/catalog/${catalog.id}`);
        if (response.status === 200) {
          return { success: true };
        } else {
          throw new Error('Failed to delete catalog'); // Throw an error for non-200 status codes
        }
      });
  
      const results = await Promise.all(deletePromises);
  
      return results;
    } catch (error: any) {
      console.error('Error deleting catalogs:', error?.response?.data);
      return { success: false, error: error?.response?.data?.detail || 'Unknown error' }; // Return error message
    }
  };
  
  export const CatalogService = {
    deleteAll,
    getAll,
    getById,
    create,
    update
  };

 