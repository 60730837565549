import { httpPdfService } from "../http-service";
import { WorkOrderPdf } from "../model/WorkOrderPdf";

const pdfStream = async (data: WorkOrderPdf) => {
  const pdfData = JSON.stringify(data);
  const response = await httpPdfService.post<WorkOrderPdf>(
    `/pdf-stream`,
    pdfData,
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
  const pdfBlob = new Blob([response.data as unknown as BlobPart], {
    type: "application/pdf"
  });
  return window.URL.createObjectURL(pdfBlob);
};

const pdfFile = async (pdfData: WorkOrderPdf) => {
  const response = await httpPdfService.post<WorkOrderPdf>(
    `/pdf-file`,
    pdfData,
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
  const pdfBlob = new Blob([response.data as unknown as BlobPart], {
    type: "application/pdf",
  });
  return window.URL.createObjectURL(pdfBlob);
};

export const pdfService = {
  pdfStream,
  pdfFile,
};
