import {
  GoAButton,
  GoAButtonGroup,
  GoAIconButton,
  GoAModal,
  GoATab,
  GoATabs,
} from "@abgov/react-components";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import WorkOrderMainTab from "./tabs/WorkOrderMainTab";
import WorkOrderPartsAndLabourTab from "./tabs/WorkOrderPartsAndLabourTab";
import { pdfService } from "../../services/PdfService";
import { useRecoilState } from "recoil";
import { WorkOrderPdf } from "../../model/WorkOrderPdf";
import { workOrderPdfState } from "../../states/WorkOrder.state";
import PageLoader from "../../components/PageLoader";

const WorkOrderView: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { isHomePage } = useParams();
  const workOrderPdfData = useRecoilState<WorkOrderPdf>(workOrderPdfState);
  const [formChanged, setFormChanged] = useState(false);
  const [showCancelModal, setShowCancelModal] =
    React.useState(false);

  const printWorkOrder = async () => {
    setIsLoading(true);
    try {
      const result = await pdfService.pdfFile(workOrderPdfData[0]);
      if (result) {
        window.open(result.toString(), "_blank", "");
      }
    } catch (error) {
      console.error("An error occurred while opening workorder pdf", error);
      alert("An error occurred while opening workorder pdf");
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageLoader visible={loading} />
      <div className="workorder-content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => window.location.pathname = '/home'}
            >
              Home
            </GoAButton>
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => navigate("/workOrderList")}
            >
              Work orders
            </GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto create-work-order-header">
            <PageHeader title={"View work order"} />
          </div>
        </div>
      </div>

      <div className="workorder-tab-container">
        <div className="workorder-print">
          <GoAIconButton icon="print" variant="dark" onClick={printWorkOrder} title="print" size="large"></GoAIconButton>
        </div>
        <GoATabs>
          <GoATab heading="Main">
            <WorkOrderMainTab  workOrderId={id} isHomePage={isHomePage==="true"} formChanged={formChanged} setFormChanged={setFormChanged} setShowCancelModal={setShowCancelModal} />
          </GoATab>
          <GoATab heading="Parts & Labour">
            <WorkOrderPartsAndLabourTab workOrderId={id} setFormChanged={setFormChanged} />
          </GoATab>
        </GoATabs>
      </div>
      <GoAModal heading="" open={showCancelModal} maxWidth="30vw"
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowCancelModal(false); }}>No</GoAButton>
            <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={() => { isHomePage==="true" ? navigate(`/home`) : navigate(`/workOrderList`) }}>Yes</GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>Are you sure you want to cancel this workorder?</p>
      </GoAModal>
    </>
  );
};
export default WorkOrderView;
