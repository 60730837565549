export interface IWorkOrderPartItem {
  locationId: string;
  locationName: string;
  catalogItemId: string;
  locationItemId: string;
 // quantityOnHand: number;
  isChecked: boolean;
  quantity: number | undefined;
  partNumber: string;
  itemName: string;
  isUsedPart: boolean;
  unitCost: number | undefined;
  partItemCost: number | undefined;
  isDeleted: boolean;
}

export class WorkOrderPartItem implements IWorkOrderPartItem {
  constructor(
    public locationId: string = "",
    public locationName: string = "",
    public catalogItemId: string = "",
    public locationItemId: string = "",
    public isChecked: boolean = false,
    //public quantityOnHand: number = 0,
    public quantity: number | undefined = 1,
    public partNumber: string = "",
    public itemName: string = "",
    public isUsedPart: boolean = false,
    public unitCost: number | undefined = 0,
    public partItemCost: number | undefined = 0,
    public isDeleted: boolean = false
  ) {}
}
