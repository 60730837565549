import { AuditTrail, IPageInfo } from "./Base";

export class Parts {
    parts: IPart[] = [];
    pageInfo: IPageInfo | undefined;
}

export interface IPart {
    isChecked: boolean;
    provStocked:boolean;
    altPartNumber: { partNumber: string; supplier: string }[] | null;
    supplier: string | null;
    forEquipment: string;
    objectType: number;
    catalogId: string;
    name: string | null;
    description: string | null;
    code: string;
    imisId: number;
    status: number;
    unit: number;
    price: {
        amount: number;
        allowAutoCost: boolean;
        costUpdatedDate: Date | null;
    };
    thumbnailURL: string | null;
    imageURL: string | null;
    id: string;
    auditTrail: AuditTrail;
}

export class Part implements IPart {
    isChecked: boolean = false;
    provStocked:boolean=false;
    altPartNumber!: { partNumber: string; supplier: string }[] | null;
    supplier!: string | null;
    forEquipment: string = "";
    objectType: number = 0;
    catalogId: string = "";
    name: string | null = null;
    description: string | null = null;
    code: string = "";
    imisId: number = 0;
    status: number = 0;
    unit: number = 0;
    price!: {
        amount: number;
        allowAutoCost: boolean;
        costUpdatedDate: Date | null;
    };
    thumbnailURL: string | null = null;
    imageURL: string | null = null;
    id: string = "";
    auditTrail!: AuditTrail;

    constructor(from?: Partial<Part>) {
       
            this.isChecked = from?.isChecked ?? false;
            this.provStocked = from?.provStocked ?? false;
            this.altPartNumber = from?.altPartNumber ? [...from.altPartNumber] : [];
            this.supplier = from?.supplier ?? null;
            this.forEquipment = from?.forEquipment ?? "";
            this.objectType = from?.objectType ?? 0;
            this.catalogId = from?.catalogId ?? "";
            this.name = from?.name ?? null;
            this.description = from?.description ?? null;
            this.code = from?.code ?? "";
            this.imisId = from?.imisId ?? 0;
            this.status = from?.status ?? 0;
            this.unit = from?.unit ?? 0;
            
            if (from && from.price) {
                let amount = typeof from.price.amount === 'string' ? parseFloat(from.price.amount) : from.price.amount;
                amount = isNaN(amount) ? 0.00 : parseFloat(amount.toFixed(2));
                this.price = {
                    ...from.price,
                    amount,
                };
            } else {
                this.price = {
                    amount: 0.00, // Default to 0.00 if no price provided
                    allowAutoCost: false,
                    costUpdatedDate: null,
                };
            }
            this.thumbnailURL = from?.thumbnailURL ?? null;
            this.imageURL = from?.imageURL ?? null;
            this.id = from?.id ?? "";
            this.auditTrail = from?.auditTrail ?? {
                createdBy: "",
                createdDate: new Date(),
                isDeleted: false,
                lastUpdatedBy: null,
                lastUpdatedDate: new Date(),
            };
       
    }
}
