

export interface IWorkOrderLabourItem {
isChecked: boolean;
labourItemId: string;
serviceCode: string;
description: string;
hours?: number | undefined;
otFactor?: number | undefined;
otFactorDesc : string;
hourlyRate?: number | undefined;
labourItemCost?: number | undefined;
isDeleted : boolean;
}
  
  export class WorkOrderLabourItem implements IWorkOrderLabourItem {
    
    constructor(
      public isChecked: boolean = false,
      public labourItemId: string = '',
      public serviceCode: string = '',
      public description: string = '',
      public hours: number | undefined = 0,
      public otFactor: number | undefined = 1,
      public otFactorDesc: string = '',
      public hourlyRate: number | undefined = 38,
      public labourItemCost: number | undefined = 0,
      public isDeleted: boolean = false
    ) {}
  }
  
  
