import React, { FunctionComponent, useState, useEffect } from 'react';
import { GoABadge, GoAButton, GoAContainer, GoAFormItem } from '@abgov/react-components';
import PageLoader from '../../components/PageLoader';
import { CommonLookup } from '../../model/Common';
import { Part } from '../../model/Part';
import { PartService } from '../../services/PartService';
import { useRecoilValue } from 'recoil';
import { partsState } from '../../states/PartService.state';
import { ForObjectType} from '../../model/enums/Constants';
import { useNavigate } from 'react-router-dom';
import { getDataFromSessionStorage } from '../../utils/SessionStorageUtils';
import { loadCommonLookups } from '../../states/Common.state';

interface ICatalogItemDetailViewProps {
  catalogName?: string;
  catalogType?: string;
  catalogId?: string;
  id: string;
  editable:boolean;
}

const CatalogItemDetailView: FunctionComponent<ICatalogItemDetailViewProps> = ({ catalogName, catalogType, catalogId, id,editable }) => {
  const navigate = useNavigate();
  const [unitOptions, setUnitOptions] = useState<{ id: number; value: string }[]>();
  const [loading, setLoading] = useState(false);
  const parts = useRecoilValue<Part[]>(partsState);
  const [formState, setFormState] = useState<FormState>({ formPart: new Part() });
  //id = '7f262e30-8c28-44e7-a6ca-472514e63cfc';
  interface FormState {
    formPart: Part;
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = getDataFromSessionStorage(ForObjectType.PART);
        if (!response) {
          loadCommonLookups();
        }

        setUnitOptions(response.find((x: CommonLookup) => x.name === 'unit')?.elements);
      } catch (error) {
        console.error("Error fetching catalog:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchFormPart = async () => {
      setLoading(true);
      try {
        if (parts) {
          const foundPart = parts.find(item => item.id === id);
          if (foundPart) {
            setFormState({ formPart: foundPart });
            return;
          }
        }

        if (id) {
          const result = await PartService.getById(id);
          setFormState({ formPart: result.data });
        }
      } catch (error) {
        console.error("Error fetching form part:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFormPart();
  }, [id, parts]);

  return (
    <>
      <PageLoader visible={loading} />
      <GoAContainer accent="thin" padding="relaxed">
        <div className="form-details-container">

          <div className="form-details-row">
            <div className='input-form-item'>
              <GoAFormItem label='Name'>
                {formState?.formPart?.name || ''}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Main part number'>
                {formState?.formPart?.code || ''}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Main supplier'>
                {formState?.formPart?.supplier || ''}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Status'>
                {formState?.formPart?.status === 1 ? <GoABadge type="success" content="Active" /> : (formState?.formPart?.status === 2 ? <GoABadge type="important" content="Inactive" /> : <GoABadge type="emergency" content="Obsolete" />)}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Unit of measure'>
                {unitOptions?.find(u => u.id === formState?.formPart?.unit)?.value}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Cost'>
                {formState?.formPart?.price?.amount.toString() || ''}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Use'>
                {formState?.formPart?.forEquipment}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Provincially stocked'>
                {formState?.formPart?.provStocked ? 'Yes' : 'No'}
              </GoAFormItem>
            </div>

            <div className='input-form-item'>
              <GoAFormItem label='Allow auto cost calculation'>
                {formState?.formPart?.price?.allowAutoCost ? 'Yes' : 'No'}
              </GoAFormItem>
            </div>

          </div>
        </div>

        <div className='input-form-item'>
          <GoAFormItem label='Alternate number & supplier'>
            {formState?.formPart?.altPartNumber?.map((item: any) => (
              <div className="alt-row altNum-supp-row">
                <div className="column">{item.partNumber}</div>
                <div className="column">{item.supplier}</div>
              </div>
            ))}
          </GoAFormItem>
        </div>

        <div className='input-form-item'>
          <GoAFormItem label='Description'>
            {formState?.formPart?.description || ''}
          </GoAFormItem>
        </div>

        {(catalogName && editable) ? <div className='align-right'><GoAButton type="tertiary" trailingIcon="pencil" onClick={() => navigate(`/catalogItemDetail/${catalogName}/${catalogType}/${catalogId}/${formState?.formPart.name}/${formState?.formPart.id}`)}>
          Update
        </GoAButton></div> : ''}
      </GoAContainer>
    </>
  );
};

export default CatalogItemDetailView;
