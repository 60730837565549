
import { httpCatalogService } from "../http-service";
import { IPart, Parts } from "../model/Part";
const BASE_URL = '/parts';
//Search
//catalogId=bb40d2a0-353c-4669-ab84-717b775c61aa&objectType=1&pageNumber=1&pageSize=10&orderBy=Name&sortDirection=ASC
const getAll = async (
  search: string,
  catalogId: string,
  objectType:string,
  pageNumber: number,
  pageSize: number,
  orderBy: string,
  sortDirection: string
) => {
  const params = new URLSearchParams({
    search: search,
    catalogId: catalogId,
    objectType: objectType,
    pageNumber: String(pageNumber),
    pageSize: String(pageSize),
    orderBy: orderBy,
    sortDirection: sortDirection,
  });
  const data = await httpCatalogService.get<Parts>(`${BASE_URL}?${params}`);

  return data;
};

  const getById = async (id: string) => {
    const data = await httpCatalogService.get<IPart>(
      `${BASE_URL}/${id}`
    );
  
    return data;
  };
 
  const create = async (part: IPart) => {
    try {
      const params = JSON.stringify(part);
      console.log(params);
  
      const response = await httpCatalogService.post<IPart>(
        `${BASE_URL}`,
        params,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      
      if (response.status === 200 || response.status === 202) {
        return { success: true };
      } else {
        throw new Error('Failed to update part'); // Throw an error for non-200 status codes
      }
    } catch (error:any) {
      console.error('Error updating part:', error?.response?.data);
      return { success: false, error: error?.response?.data?.detail || 'Unknown error' }; // Return error message
    }
  };
  
  const update = async (part: IPart) => {
    try {
      const params = JSON.stringify(part);
      console.log(params);
  
      const response = await httpCatalogService.put<IPart>(
        `${BASE_URL}`,
        params,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
 
      if (response.status === 200) {
        return { success: true };
      } else {
        throw new Error('Failed to update part'); // Throw an error for non-200 status codes
      }
    } catch (error:any) {
      console.error('Error updating part:', error?.response?.data);
      return { success: false, error: error?.response?.data?.detail || 'Unknown error' }; // Return error message
    }
  };
  
  const deleteAll = async (parts: IPart[]) => {
    try {
      const deletePromises = parts.map(async (part: IPart) => {
         
        const response = await httpCatalogService.delete<Parts>(`${BASE_URL}/${part.id}`);
        if (response.status === 200) {
          return { success: true };
        } else {
          throw new Error('Failed to delete part'); // Throw an error for non-200 status codes
        }
      });
  
      const results = await Promise.all(deletePromises);
  
      return results;
    } catch (error: any) {
      console.error('Error deleting parts:', error?.response?.data);
      return { success: false, error: error?.response?.data?.detail || 'Unknown error' }; // Return error message
    }
  };
  
  export const PartService = {
    getAll,
    getById,
    create,
    update,
    deleteAll
  };

 