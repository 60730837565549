import * as React from "react";
import { loadCommonLookups } from "../../states/Common.state";
import WorkOrderList from "../workOrder/WorkOrderList";
import Select, { InputActionMeta } from "react-select";
import { GoAFormItem, GoATab, GoATabs } from "@abgov/react-components";
import { debounce } from "lodash";
import { LocationService } from "../../services/LocationService";
import { useRecoilState } from "recoil";
import { workOrderListSearchState } from "../../states/WorkOrder.state";
import { SelectOptions } from "../../model/Base";

const Home: React.FunctionComponent = () => {
  const [locations, setLocations] = React.useState<SelectOptions[]>([]);
  const [selectedLocation, setSelectedLocation] = React.useState<any>();
  const [search, setSearch] = useRecoilState<string>(workOrderListSearchState);
 // const [searchValue, setSearchValue] = useRecoilState<string>(workOrderListSearchState);



  const onLocationSearchChange = (
    inputValue: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change") {
      loadLocationOptions(inputValue, (result: any) => {
        setLocations(result);
      });
    }
  };

  const loadLocationOptions = debounce(
    async (inputValue: string, callback: (data: any[]) => void) => {
      if (!inputValue) return;
      const data = await LocationService.getList(inputValue);
      // Pass the transformed options to the callback function
      callback(data);
    },
    500
  );

  React.useEffect(() => {
    loadCommonLookups();
    loadLocationOptions(search, (result: any) => {
      setLocations(result);
      setSelectedLocation(result[0].value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocationChange = (selectedLocation: any) => {
    setSelectedLocation(selectedLocation);
    setSearch(selectedLocation.value);
  };

  return (
    <div className="home-page-container">
      <div className="home-page-workflow-label">Workflow</div>
      <div style={{display: 'none'}}>{process.env.REACT_APP_ENV} </div>
      <div className="home-page-location-dropdown">
        <GoAFormItem label="Location">
          <Select
            name="location"
            options={locations}
            placeholder="--Select--"
            className="width100 select-input"
            isDisabled={false}
            isSearchable={true}
            onInputChange={onLocationSearchChange}
            onChange={handleLocationChange}
            value={locations.find((i) => i.value === selectedLocation)}
          />
        </GoAFormItem>
      </div>
      <GoATabs>
        <GoATab heading="Work orders">
          <WorkOrderList
            isHomePage={true}
          />
        </GoATab>
      </GoATabs>
    </div>
  );
};

export default Home;
