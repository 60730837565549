export class AuditTrail {
    constructor(
        public createdBy: string,
        public createdDate: Date,
        public isDeleted: boolean,
        public lastUpdatedBy: string | null,
        public lastUpdatedDate: Date | null
    ) { }
}

export interface IPageInfo {
    pageSize: number;
    currentPage: number;
    total: number;
}
export class PageInfo implements IPageInfo {
    pageSize: number = 1;
    currentPage: number = 1;
    total: number = 0
};

export class UserComment {
    constructor(
        public id: number | undefined,
        public text: string,
        public username: string,
        public postDate: string
    ) {}
}

export interface SelectOptions {
    value: string;
    label: string;
}

