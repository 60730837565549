import * as React from "react";
import { Route, Routes } from "react-router-dom";
import AppHeader from "./AppHeader";
import Home from "../pages/home/Home";
import CatalogDetail from "../pages/catalog/CatalogDetail";
import CatalogItemsList from "../pages/catalog/CatalogItemList";
import CatalogItemDetail from "../pages/catalog/CatalogItemNew";
import CatalogList from "../pages/catalog/CatalogList";
import AdjustmentList from "../pages/inventory/AdjustmentList";
import AdjustmentNew from "../pages/inventory/AdjustmentNew";
import AdjustmentView from "../pages/inventory/AdjustmentView";
import WorkOrderList from "../pages/workOrder/WorkOrderList";
import CreateWorkOrder from "../pages/workOrder/WorkOrderNew";
import { useAuth } from "react-oidc-context";
import { GoACircularProgress } from "@abgov/react-components";
import WorkOrderView from "../pages/workOrder/WorkOrderView";
import PageTitle from "./PageTitle";
import LocationSnapshot from "../pages/location/LocationSnapshot";
import { hasResourceRole } from "../utils/tokens";
import { permissions } from "../common/constants";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import { LoggedOut } from "./LoggedOut";

interface ILayoutProps { }
const appTitles = {
  'home': 'Home | Warehouse Management',
  'catalogueList': 'Catalogues list | Warehouse Management',
  'catalogDetail': 'Catalogue detail | Warehouse Management',
  'catalogItemDetail': 'Catalogue item detail | Warehouse Management',
  'catalogItemsList': 'Catalogue items list | Warehouse Management',
  'adjustmentList': 'Adjustments list | Warehouse Management',
  'adjustmentView': 'Adjustment detail | Warehouse Management',
  'adjustmentNew': 'Create adjustment | Warehouse Management',
  'workOrderList': 'WorkOrders list | Warehouse Management',
  'workOrderView': 'WorkOrder detail | Warehouse Management',
  'createWorkOrder': 'Create work order | Warehouse Management',
  'locationSnapshot': 'Location snapshot | Warehouse Management',
  
  // Add more routes and titles as needed
};
const Layout: React.FunctionComponent<ILayoutProps> = (props) => {
  const auth = useAuth();
  const canViewWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_VIEW,
    auth.user?.access_token ?? ""
  );
  const canCreateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_CREATE,
    auth.user?.access_token ?? ""
  );
  const canViewCatalog = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_VIEW,
    auth.user?.access_token ?? ""
  );
  const canCreateUpdateCatalog = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_WRITE,
    auth.user?.access_token ?? ""
  );
  const canViewCatalogItem = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_ITEM_VIEW,
    auth.user?.access_token ?? ""
  );
  const canCreateUpdateCatalogItem = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_ITEM_WRITE,
    auth.user?.access_token ?? ""
  );


  // const canViewConsumableAdjustments = hasResourceRole(
  //   String(process.env.REACT_APP_IMIS_CLIENT),
  //   permissions.IMS_ADJUSTMENT_VIEW,
  //   auth.user?.access_token ?? ""
  // );
  // const canCreateUpdateConsumableAdjustments = hasResourceRole(
  //   String(process.env.REACT_APP_IMIS_CLIENT),
  //   permissions.IMS_ADJUSTMENT_WRITE,
  //   auth.user?.access_token ?? ""
  // );
  const canViewLocations = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_LOCATION_VIEW,
    auth.user?.access_token ?? ""
  );

  const canCreateAdjustment = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_ADJUSTMENT_WRITE,
    auth.user?.access_token ?? ""
  );

  return (
    <>
      <div className="wrapper">
        <AppHeader />
        <PageTitle titles={appTitles} />
        <div className={"row container"}>
          <div className="content col-12">
            {
             !auth.isLoading && 
            
            <Routes>
              <Route path="/home" element={canViewWorkOrder ? <Home /> : <Unauthorized/>} />
              <Route path="*" element={canViewWorkOrder ? <Home /> : <Unauthorized/>} />
              <Route path='/logged-out' element={<LoggedOut />} />
              <Route path="/catalogueList" element={canViewCatalog ? <CatalogList /> : <Unauthorized/>} />
              <Route path="/catalogDetail/:id" element={canCreateUpdateCatalog ? <CatalogDetail /> : <Unauthorized/>} />
              <Route path="/catalogItemsList/:name/:catalogType/:id/:status" element={canViewCatalogItem ? <CatalogItemsList /> : <Unauthorized/>} />
              <Route path="/catalogItemsList/:name/:catalogType/:id" element={canViewCatalogItem ? <CatalogItemsList /> : <Unauthorized/>} />
              <Route path="/catalogItemDetail/:catalogName/:catalogType/:catalogId/:itemName/:id" element={canCreateUpdateCatalogItem ? <CatalogItemDetail /> : <Unauthorized/>} />
              <Route path="/catalogItemDetail/:catalogName/:catalogType/:catalogId/:id" element={canCreateUpdateCatalogItem ? <CatalogItemDetail /> : <Unauthorized/>} />
              <Route path="/catalogItemDetail/:id" element={canCreateUpdateCatalogItem ? <CatalogItemDetail /> : <Unauthorized/>} />
              {/* <Route path="/catalogItemDetailView/:id" element={<CatalogItemDetailView />} /> */}
              <Route path="/adjustmentList" element={<AdjustmentList />} />
              <Route path="/adjustmentNew" element={canCreateAdjustment?<AdjustmentNew /> : <Unauthorized/>} />
              <Route path="/adjustmentView/:adjustmentNo/:id" element={<AdjustmentView />} />
              <Route path="/workOrderList" element={canViewWorkOrder ? <WorkOrderList /> : <Unauthorized/>} />
              <Route path="/workOrderView/:id"  element={canViewWorkOrder ? <WorkOrderView /> : <Unauthorized/>} />
              <Route path="/workOrderView/:id/:isHomePage"  element={canViewWorkOrder ? <WorkOrderView /> : <Unauthorized/>} />
              <Route path="/createWorkOrder" element={canCreateWorkOrder ? <CreateWorkOrder /> : <Unauthorized/>} /> 
              <Route path="/locationSnapshot" element={canViewLocations ? <LocationSnapshot /> : <Unauthorized/>} /> 
              ConsumableAdjustment
            </Routes>}
            {auth.isLoading && <GoACircularProgress visible={true} />} 
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
