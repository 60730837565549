import {
  GoABadge,
  GoABlock,
  GoAButton,
  GoAButtonGroup,
  GoACheckbox,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAModal,
  GoAPagination,
  GoASpacer,
  GoATable,
  GoATableSortHeader,
} from "@abgov/react-components";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageLoader from "../../components/PageLoader";
import { Catalog } from "../../model/Catalog";
import { CatalogService } from "../../services/CatalogService";
import moment from "moment";
import SearchInput from "../../components/searchInput";
import { Fragment, FunctionComponent, useCallback, useState } from "react";
import CatalogView from "./CatalogView";
import { CalloutVariant, ForObjectType, LookupType } from "../../model/enums/Constants";
import { getDataFromSessionStorage } from "../../utils/SessionStorageUtils";
import { CommonLookup } from "../../model/Common";
import { useAuth } from "react-oidc-context";
import { hasResourceRole } from "../../utils/tokens";
import { permissions } from "../../common/constants";
import { CommonService } from "../../services/CommonService";

interface ICatalogProps { }

const CatalogList: FunctionComponent<ICatalogProps> = (
  props
) => {
  // Navigation
  const navigate = useNavigate();
  //Loader
  const [loading, setIsLoading] = useState(true);
  //Search
  const [searchValue, setSearchValue] = useState("");
  const [catalogs, setCatalogs] = useState<Catalog[]>([]);

  // page number
  const [page, setPage] = useState(1);

  //count per page
  const [perPage, setPerPage] = useState(25);

  const [catalogTotalCount, setTotalCount] = useState(0);

  //Sorting
  const [sortCol, setSortCol] = useState("lastUpdatedDate");
  const [sortDir, setSortDir] = useState(0);

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null); // State to track expanded row

  const [showDeleteModal, setShowDeleteModal] =
    React.useState(false);
  const [catalogTypesString, setCatalogTypesString] = useState('')
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);
  const [showNotificationMessage, setShowNotificationMessage] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({}); // Define errors as Record<string, string>
  const [deleteConfirm, setDeleteConfirm] =
    React.useState(false);
    const getData = useCallback(async (catalogTypesString: string = '') => {
      try {
        const response = await CatalogService.getAll(
          searchValue,
          catalogTypesString ?? '',
          page,
          perPage,
          sortCol,
          sortDir === 1 ? "ASC" : "DESC"
        );
    
        if (response) {
          setTotalCount(response?.data?.pageInfo?.total ?? 0);
          setCatalogs(response.data.catalogs);
        }
      } catch (error) {
        console.error('Error fetching catalogues:', error);
        setIsLoading(false);
      }
    }, [searchValue, page, perPage, sortCol, sortDir]);
    
    
  React.useEffect(() => {

    const getCatalogs = async () => {


      setIsLoading(true);
      let catalogLookups = getDataFromSessionStorage(ForObjectType.CATALOG);

      if (!catalogLookups) {
        catalogLookups = await CommonService.getCommonLookups(
          ForObjectType.CATALOG,
          [LookupType.STATUS, LookupType.CATALOG_TYPE]
        );
      }

      const catalogTypes = (catalogLookups?.find((x: CommonLookup) => x.name === 'CatalogType')?.elements);
      const catalogTypesString = catalogTypes?.map((type: any) => type.id).join(', ');
      setCatalogTypesString(catalogTypesString);

      getData(catalogTypesString);
      setIsLoading(false);

    };

    getCatalogs();
  }, [getData, searchValue, page, perPage, sortCol, sortDir, setCatalogs, setTotalCount]);



  function searchCataloges(searchParamValue: string) {
    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setPage(1);
      setSearchValue(searchParamValue);
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
    }
  }

  function setCheckCatalog(checked: boolean, index: number) {
    const updatedCatalogs = catalogs.map((c, i) =>
      i === index ? { ...c, isChecked: checked } : c
    );
    setCatalogs(updatedCatalogs);
  }

  function setCheckAllCatalog(checked: boolean) {
    const catalogsCopy = catalogs?.map((record: Catalog) => ({
      ...record,
      isChecked: checked
    }));

    setCatalogs(catalogsCopy);
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);
  }

  function changePerPage(name: any, value: any) {
    if (value) {
      const newPerPage = parseInt(value, 10);
      const offset = (page - 1) * newPerPage;
      const _catalogs = catalogs?.slice(
        offset,
        offset + newPerPage
      );

      setCatalogs(_catalogs);
      setPerPage(p => {
        return newPerPage;
      });
      setPage(page);
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    if (newPage) {
      setPerPage(perPage);
      setPage(newPage);
    }
  }

  const clearSearch = () => {
    setSearchValue('');
  };

  const toggleRowExpand = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const deleteCatalogs = () => {
    setShowDeleteModal(true);
  }

  const confirmDeleteCatalogs = async () => {
    if (!validateForm('delete'))
      return;

    setShowDeleteModal(false);

    const catalogsForDelete = catalogs.filter((catalog: Catalog) => catalog.isChecked);
    const results = await CatalogService.deleteAll(catalogsForDelete);

    if (Array.isArray(results)) {
      const allSuccessful = results.every(result => result.success);
      const firstError = results.find(result => !result.success);

      if (allSuccessful) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("All catalouges deleted successfully!");
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(`Failed to delete some catalogue: ${firstError}`);
      }
      getData(catalogTypesString);
    } else {
      setCalloutVariant(CalloutVariant.EMERGENCY);
      setNotificationMessage(`Failed to delete catalogue: ${results.error}`);
    }

    setShowNotificationMessage(true);

  };

  function validateForm(name: string, value: string | string[] = '') {
    const newErrors: Record<string, string> = {};
    // Check for errors in form fields
    if ((name === 'delete' && !deleteConfirm) || (name === "deleteConfirm" && value !== "checked")) {
      newErrors['deleteConfirm'] = 'required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }
  const auth = useAuth();
  const canCreateCatalog = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_WRITE,
    auth.user?.access_token ?? ""
  );
  const canDeleteCatalog = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_DELETE,
    auth.user?.access_token ?? ""
  );


  return (
    <>
      <PageLoader visible={loading} />
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" onClick={() => navigate(``)}>Catalogues</GoAButton>
          </div>
        </div>



        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title="Catalogues" />
          </div>
          {canCreateCatalog && <div className="right width50 paddingLeft20px input-search align-right">
            <GoAButton type="primary" trailingIcon="add" onClick={() => { navigate(`/catalogDetail/new`) }}>
              Create catalogue
            </GoAButton>
          </div>}
        </div>

        <div className="command-bar">
          <div className="left width-auto header-button form-row">
            {canDeleteCatalog && <div>
              <GoAButton size="compact" type="tertiary" onClick={deleteCatalogs} disabled={catalogs?.filter(x => x.isChecked).length === 0}>
                Delete catalog(s)
              </GoAButton>
            </div>}
          </div>
          <div className="right width50 paddingLeft20px input-search">
            <SearchInput label="" search={searchCataloges} clearSearch={clearSearch} placeholder="Search by name, description"></SearchInput>
          </div>
        </div>

        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                {canDeleteCatalog && <th className="check-item">
                  <GoACheckbox
                    name="check"
                    text=""
                    value="10"
                    checked={false}
                    onChange={(name, checked, value) => {
                      setCheckAllCatalog(checked);
                    }}
                  />
                </th>}
                <th>
                  <GoATableSortHeader name="Name">
                    Name
                  </GoATableSortHeader >
                </th>
                <th> <GoATableSortHeader name="description">
                  Description
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="status">
                  Status
                </GoATableSortHeader ></th>
                <th>
                  Browse
                </th>
                <th><GoATableSortHeader name="createdDate">
                  Created on
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="lastUpdatedDate">
                  Last updated
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="createdBy">
                  Created by
                </GoATableSortHeader ></th>
                <th>

                </th>

              </tr>
            </thead>
            <tbody>
              {catalogs?.map((record: Catalog, index: any) => (
                <Fragment key={index}>
                  <tr key={record.id}>
                    {canDeleteCatalog && <td className="check-item">
                      <GoACheckbox
                        name="isChecked"
                        text=""
                        value={record.isChecked}
                        checked={record.isChecked}
                        onChange={(name, checked, value) => {
                          setCheckCatalog(checked, index);
                        }}
                      /></td>}
                    <td>
                      {record.name}
                    </td>
                    <td>{record.description}</td>
                    <td>
                      {record.status === 1 ? (
                        <GoABadge type="success" content="Active" />
                      ) : record.status === 2 ? (
                        <GoABadge type="emergency" content="Inactive" />
                      ) : (
                        <GoABadge type="important" content="Pending" />
                      )}
                    </td>
                    <td><GoAButton size="compact" type="tertiary" onClick={() => navigate(`/catalogItemsList/${record.name}/${record.catalogType}/${record.id}/${record.status}`)}>
                      Items
                    </GoAButton></td>
                    <td>{moment(record.auditTrail.createdDate).format("yyyy-MM-DD")}</td>
                    <td>{moment(record.auditTrail.lastUpdatedDate).format("yyyy-MM-DD")}</td>
                    <td>{record.auditTrail.createdBy}</td>
                    <td>
                      <div className="flex">
                        <GoAButton
                          size="compact"
                          type="tertiary"
                          disabled={!record.id}
                          onClick={() => toggleRowExpand(index)}
                        >
                          <goa-icon type={(expandedRowIndex === index) ? "chevron-down" : "chevron-forward"}></goa-icon>
                        </GoAButton>
                      </div></td>

                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={9}><CatalogView id={record.id} /></td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </GoATable>
          <div className={catalogTotalCount > 0 ? "visible pagination pagination-div" : "not-visible pagination"}>
            <GoABlock alignment="center">
              <GoABlock mb="m" alignment="center" gap="m">
                <span style={{ whiteSpace: "nowrap" }}>Show</span>
                <div className="dropdown-list">
                  <GoADropdown name="selPerPage" onChange={changePerPage} value="25" width="8ch">
                    <GoADropdownItem value="25"></GoADropdownItem>
                    <GoADropdownItem value="50"></GoADropdownItem>
                    <GoADropdownItem value="100"></GoADropdownItem>
                  </GoADropdown>
                </div>
                <span style={{ whiteSpace: "nowrap" }}>
                  of {catalogTotalCount} items
                </span>
              </GoABlock>
              <GoASpacer hSpacing="fill" />
              <GoAPagination
                itemCount={catalogTotalCount || 10}
                perPageCount={perPage}
                pageNumber={page}
                onChange={changePage}
              />
            </GoABlock>

            <GoAModal heading="Delete catalogue" open={showDeleteModal} maxWidth="25vw"
              actions={
                <GoAButtonGroup alignment="end">
                  <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowDeleteModal(false); }}>Cancel</GoAButton>
                  <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={confirmDeleteCatalogs}>Delete</GoAButton>
                </GoAButtonGroup>
              }
            >
              <div>
                Are you sure you want to delete the {catalogs?.filter(x => x.isChecked).length} catalogue
                {catalogs?.filter(x => x.isChecked).length > 1 && 's'} (
                {catalogs?.filter(x => x.isChecked).map((x, index) => (
                  <strong key={index}>
                    {x.name}
                    {index < catalogs.filter(y => y.isChecked).length - 1 && ', '}
                  </strong>
                ))})?
              </div>

              <div className='input-form-item'>
                <GoAFormItem error={errors['deleteConfirm']}>
                  <GoACheckbox
                    name="deleteConfirm"
                    text={`Yes, I am sure.`}
                    checked={deleteConfirm}
                    onChange={(name, checked, value) => {
                      validateForm(name, value);
                      setDeleteConfirm(checked);
                    }}
                  />
                </GoAFormItem>
              </div>

            </GoAModal>

            <GoAModal
              heading={"Delete catalogue(s)"}
              calloutVariant={calloutVariant}
              open={showNotificationMessage}
              actions={
                <GoAButtonGroup alignment="end">
                  <GoAButton onClick={() => {
                    setShowNotificationMessage(false);
                  }}>

                    Okay
                  </GoAButton>
                </GoAButtonGroup>
              }
            >
              <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
            </GoAModal>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogList;
