import { httpAviationService } from "../http-service";



const getFireNumbers = async (search:string) => {
  const currentYear: number = new Date().getFullYear();
  const request: FireNumberSearchRequest = {
    search: search,
    year: currentYear.toString(),
    paginationInfo: {
      perPage: 200000,
      page: 1,
    }
  };
  const params = JSON.stringify(request);

  const response = await httpAviationService.post<ApiResponse>(
    `/fire-number/get`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const extractedFireNumbers = response.data?.data?.map((item: DataItem) => ({ fireNumber: item.fireNumber, year: item.year }));
  return extractedFireNumbers?.sort((a, b) => a.fireNumber.localeCompare(b.fireNumber));
};

interface PaginationInfo {
  perPage: number;
  page: number;
}

export interface FireNumberSearchRequest {
  search: string;
  year: string;
  paginationInfo: PaginationInfo;
}


interface DataItem {
  id: number;
  fireNumber: string;
  year: number;
  carryOver: string;
}

interface ApiResponse {
  status: string;
  errorCodeId: string;
  errorMessage: string;
  paginationInfo: PaginationInfo;
  data: DataItem[];
}


export const AviationService = {
  getFireNumbers
};

