
import { httpCommonService } from "../http-service";
import { CommonLookup } from "../model/Common";
import { ForObjectType, LookupType } from "../model/enums/Constants";

const getCommonLookups = async (forObject: ForObjectType, lookupTypes: LookupType[]) => {
  const lookupParams = lookupTypes?.map(type => `${type}=${forObject}`).join("&");
  const url = `/lookup?${lookupParams}`;
  const response = await httpCommonService.get<CommonLookup[]>(url);
  const data = response.data;
  
  // Sort the elements array in each CommonLookup object by the value property
  data.forEach(lookup => {
    lookup.elements.sort((a, b) => a.value.localeCompare(b.value));
  });

  return data;
};


export const CommonService = {
  getCommonLookups,
};

