import React from 'react';
import * as ReactDOM from 'react-dom/client';
 
import App from './App';

import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './common/kcConfig';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  
  <AuthProvider {...oidcConfig}>
  <React.StrictMode>
    <div className="wrapper">
      <div className={"row container"}>
        <div className="content col-12">
          <React.StrictMode>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
            </LocalizationProvider>
          </React.StrictMode>
        </div>
      </div>
    </div>
  </React.StrictMode>
 </AuthProvider>,
);
