import React from 'react';
import PropTypes from 'prop-types';
import { GoAFormItem } from '@abgov/react-components';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  label: string;
  placeholder: string;
  search: (value: string) => void;
  clearSearch: () => void;
}

const SearchInput: React.FC<Props> = ({ label, placeholder, search, clearSearch }) => {
  const [searchValue, setSearchValue] = React.useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    search(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    clearSearch();
  };

  return (
    <GoAFormItem label={label}>
      <TextField
        variant="outlined"
        fullWidth
        value={searchValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClearSearch} edge="end">
                {searchValue ? <ClearIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </GoAFormItem>
  );
};

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default SearchInput;
