import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = ( appTitles:any ) => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split('/');
    document.title = appTitles.titles[path[1]] || 'Home | Warehouse Management';
  }, [location, appTitles]);

  return null;
};

export default PageTitle;
