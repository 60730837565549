import React, { useState } from 'react';
import { GoAButton, GoAButtonGroup, GoADropdown, GoADropdownItem, GoAFormItem, GoAInputText, GoAModal } from '@abgov/react-components';
import PageLoader from '../../components/PageLoader';
import PageHeader from '../../components/PageHeader';
import { Catalog, CatalogToCreate } from '../../model/Catalog';
import { CatalogService } from '../../services/CatalogService';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { CommonLookup } from '../../model/Common';
import { CalloutVariant, ForObjectType} from '../../model/enums/Constants';
import { getDataFromSessionStorage } from '../../utils/SessionStorageUtils';
import { loadCommonLookups } from '../../states/Common.state';

const CatalogDetail = () => {
  const [formCatalog, setCatalog] = useState<Catalog>(); // Define formCatalog as Partial<Catalog>
  const [statusOptions, setStatusOptions] = useState<{ id: number; value: string }[]>();
  const [catalogTypeOptions, setCatalogTypeOptions] = useState<{ id: number; value: string }[]>();

  const [loading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({}); // Define errors as Record<string, string>
  const [formChanged, setFormChanged] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] =
    React.useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [modalHeading] = React.useState("");
  const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.SUCCESS);
  const [descriptionCharCount, setDescriptionCharCount] = useState<number>(Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT));

  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = getDataFromSessionStorage(ForObjectType.CATALOG);
        if (!response) {
          loadCommonLookups();
        }
        setStatusOptions(response.find((x: CommonLookup) => x.name === 'status')?.elements);
        setCatalogTypeOptions(response.find((x: CommonLookup) => x.name === 'CatalogType')?.elements);

      } catch (error) {
        console.error("Error fetching catalog:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      if (id === "new") {
        setCatalog(new Catalog());
      } else if (id) {
        setIsLoading(true);
        try {
          const response = await CatalogService.getById(id);
          setCatalog(response.data);
          setDescriptionCharCount(Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT) - response.data.description.length);
        } catch (error) {
          console.error("Error fetching catalog:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function setValue(value: Partial<Catalog>) {
    setCatalog(new Catalog({ ...formCatalog, ...value }));
  }

  const createUpdateClick = async () => {
    if (!validateForm('save')) {
      console.log("Form contains errors. Cannot submit.");
      return;
    }

    try {
      let result;
      if (formCatalog?.id) {
        result = await CatalogService.update(formCatalog!);
      } else {
        result = await CatalogService.create(new CatalogToCreate(formCatalog!));
      }

      if (result && result.success) {
        setCalloutVariant(CalloutVariant.SUCCESS);
        setNotificationMessage("Catalog saved successfully!");
        setShowNotificationMessage(true);
        setFormChanged(false);
      } else {
        setCalloutVariant(CalloutVariant.EMERGENCY);
        setNotificationMessage(result.error);
        setShowNotificationMessage(true);
      }
    } catch (error) {
      console.error("Error updating or creating catalog:", error);
      alert("An error occurred while updating or creating the catalog");
    }
  }

  function validateForm(name: string, value: string | string[] = '') {
    const newErrors: Record<string, string> = {};
    // Check for errors in form fields
    if ((name === 'save' && !formCatalog?.name) || (name === "name" && !value)) {
      newErrors['name'] = 'Name is required';
    }
    if ((name === 'save' && formCatalog?.name && formCatalog?.name.length < 5) || (name === "name" && value.length < 5)) {
      newErrors['name'] = 'The length of Name must be at least 5 characters';
    }
    if ((name === 'save' && !formCatalog?.catalogType) || (name === "catalogType" && !value)) {
      newErrors['catalogType'] = 'Category is required';
    }

    if ((name === 'save' && !formCatalog?.status) || (name === "status" && !value)) {
      newErrors['status'] = 'Status is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }

  // Event handler for input change
  const handleFieldChange = (name: string, value: string | string[]) => {
    setValue({ [name]: value }); // Update formCatalog
    validateForm(name, value); // Trigger validation
    setFormChanged(true);
  };

  const confirmClick=() => { 
    setShowNotificationMessage(false); 
    if(calloutVariant=== CalloutVariant.SUCCESS)
    navigate(`/catalogueList`); 
  }

  return (
    <>
      <PageLoader visible={loading} />
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => navigate(`/catalogueList`)}>Catalogues</GoAButton>
            <GoAButton type="tertiary" onClick={() => {}}><div className='view-nav'>{id === 'new' ? 'Create catalogue' : 'Update catalogue'}</div></GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title={id === 'new' ? 'Create catalogue' : 'Update catalogue'} />
          </div>
        </div>
      <div className='form-detail-container'>
          <div className='input-form-item'>
            <GoAFormItem label='Name' error={errors['name']}>
              <GoAInputText
                name="name"
                value={formCatalog?.name || ''}
                width="100%"
                maxLength={100}
                onChange={handleFieldChange} // Use handleFieldChange as event handler
              />
            </GoAFormItem>
          </div>
          <div className='input-form-item'>
            <GoAFormItem label="Description" id="firstName" requirement="optional">
               <textarea className="form-item-textarea" name="description" value={formCatalog?.description || ''} 
                 onChange={(e) => {
                  handleFieldChange("description", e.currentTarget.value);
                  setDescriptionCharCount(Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT) - e.currentTarget.value.length);
                 }}
                 style={{width: '700px'}} maxLength={Number(process.env.REACT_APP_IMIS_TEXTAREA_CHARACTER_COUNT)}
                 rows={5}>
                </textarea>
               <div style={{width: '700px'}}><label className="workorder-char-count-label">{`${descriptionCharCount} characters remaining`}</label></div>
            </GoAFormItem>
          </div>
          {!formCatalog?.id && (
            <div>
              <div className='input-form-item'>
                <GoAFormItem label='Category' error={errors['catalogType']}>
                  <GoADropdown name="catalogType" width="100%" placeholder='Select'
                    value={formCatalog?.catalogType?.toString()}
                    onChange={handleFieldChange}
                  >
                    {catalogTypeOptions?.map((item: any) => (
                      <GoADropdownItem label={item.value} value={item.id} key={item.id}></GoADropdownItem>
                    ))}
                  </GoADropdown>
                </GoAFormItem>
              </div>
            </div>)}

          <div className='input-form-item'>
            <GoAFormItem label='Status' error={errors['status']}>
              <GoADropdown name="status" width="100%" placeholder='Select'
                value={formCatalog?.status?.toString()}
                onChange={handleFieldChange} // Use handleFieldChange as event handler
              >
                {statusOptions?.map((item: any) => (
                  <GoADropdownItem label={item.value} value={item.id} key={item.id}></GoADropdownItem>
                ))}
              </GoADropdown>
            </GoAFormItem>
          </div>

          {formCatalog?.id && (
            <div>
              <div className='input-form-item'>
                <GoAFormItem label='Category'>
                  {catalogTypeOptions?.find(x => x.id === formCatalog?.catalogType)?.value}
                </GoAFormItem>
              </div>
              <div className='input-form-item'>
                <GoAFormItem label='Created'>
                  {moment(formCatalog?.auditTrail.createdDate).format("yyyy-MM-DD HH:mm:ss")}
                </GoAFormItem>
              </div>
              <div className='input-form-item'>
                <GoAFormItem label='Last updated'>
                  {moment(formCatalog?.auditTrail.lastUpdatedDate).format("yyyy-MM-DD HH:mm:ss")}
                </GoAFormItem>
              </div>
              <div className='input-form-item'>
                <GoAFormItem label='Created by'>
                  {formCatalog?.auditTrail.createdBy}
                </GoAFormItem>
              </div>
            </div>
          )}
          <div className='input-form-item right'>
            <GoAButton type="secondary" onClick={() => navigate(`/catalogueList`)} >
              Cancel
            </GoAButton>
            <div className='button-spacing'></div> {/* Add space between buttons */}
            <GoAButton type="primary" onClick={createUpdateClick} disabled={Object.keys(errors).length > 0 || !formChanged}>
              {id === 'new' ? 'Create' : 'Update'}
            </GoAButton>
          </div>
        </div>
        </div>
 
      <GoAModal
        heading={modalHeading}
        calloutVariant={calloutVariant}
        open={showNotificationMessage}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton onClick={confirmClick}>
              Okay
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
      </GoAModal>
      {/* 
        <GoAModal
          heading="You have unsaved changes"
          open={showModal}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => onCancel()}>Cancel</GoAButton>
              <GoAButton onClick={() => onDiscardChanges()}>
                Discard changes
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <p>Are you sure you want to discard changes?</p>
        </GoAModal> */}
    </>
  );
};

export default CatalogDetail;
