// sessionStorageUtils.ts


export interface SessionData<T> {
  value: T;
  timestamp: number;
}

export const saveDataToSessionStorage = (key: string, sessionData: any): void => {
  const data: SessionData<any> = {
    value: sessionData,
    timestamp: new Date().getTime(), // Record the current timestamp
  };
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromSessionStorage = (key: string): any | null => {
  const storedData = sessionStorage.getItem(key);
  if (storedData) {
    const { value, timestamp }: SessionData<any> = JSON.parse(storedData);
    const currentTime = new Date().getTime();
    const expiryTime = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

    // Check if the data has expired
    if (currentTime - timestamp > expiryTime) {
      // Data has expired, remove it from sessionStorage
      sessionStorage.removeItem(key);
      return null;
    }

    // Data is still valid, return it
    return value;
  }
  return null; // No data found
};
