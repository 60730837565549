import { httpWorkOrderService } from "../http-service";
import { WorkOrderLabourItem } from "../model/WorkOrderLabour";
import { IUnitItem } from "../model/Unit";
import { WorkOrder, WorkOrderRequest, WorkOrderResponse } from "../model/WorkOrder";
import { formatPDFDate } from "../common/date";

const getAll = async (
  search: string,
  pageNumber: number,
  pageSize: number,
  orderBy: string,
  sortDirection: string,
  startDate?: Date,
  endDate?: Date,
  status?:number,
  name?:string

) => {
  const params = new URLSearchParams({
    search: search,
    pageNumber: String(pageNumber),
    pageSize: String(pageSize),
    orderBy: orderBy,
    sortDirection: sortDirection,
  });

  if (startDate) {
    params.append('startDate', startDate.toDateString());
  }

  if (endDate) {
    params.append('endDate', endDate.toDateString());
  }

  if (status) {
    params.append('status', status.toString());
  }

  if (name) {
    params.append('name', name);
  }

  const data = await httpWorkOrderService.get<WorkOrderResponse>(`/workorder?${params}`);

  return data;
};

const getAllLegacy = async (
  pageNumber : number, pageSize : number, orderBy: string,
  sortDirection: string
  ) => {
    const params = new URLSearchParams({
      pageNumber: "1",
      pageSize: "5000",
      orderBy: orderBy,
      sortDirection: sortDirection,
    });
    const data = await httpWorkOrderService.get<WorkOrderResponse>(`/workorder/legacy?${params}`);
    return data;
  };

const getById = async (
  id : string
) => {
  const data = await httpWorkOrderService.get<WorkOrder>(`/workorder/${id}`);
  data.data.currentDateTime = formatPDFDate(new Date());
  return data;
};

const searchUnits = async (
  search: string, isAltRefNoField: boolean
) => {
  const response = await httpWorkOrderService.get<IUnitItem[]>(`workorder/units?search=${search}&isAltRefNoField=${isAltRefNoField}`);
  return response.data;
};

const searchLabours = async (
  search: string=''
) => {
  const params = new URLSearchParams({
    search: search,
    pageNumber: '1',
    pageSize:'5000',
    orderBy: 'ServiceCode',
    sortDirection: 'ASC',
  });

  const response = await httpWorkOrderService.get<WorkOrderLabourItem[]>(`workorder/labours?${params}`);

  return response.data;
};

const importAll = async (workOrderRequest: WorkOrderRequest) => {
  try {
    const response = await httpWorkOrderService.post<WorkOrderRequest>(
      `/workorder/import`,
      workOrderRequest,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to import work orders'); // Throw an error for non-200 status codes
    }
  } catch (error:any) {
    console.error('Error importing work orders:', error?.response?.data?.detail);
    return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
  }
};

const create = async (newWorkOrder: WorkOrder) => {
  try {
    const workOrder = JSON.stringify(newWorkOrder);
    console.log(workOrder);

    const response = await httpWorkOrderService.post<WorkOrder>(
      `/workorder`,
      newWorkOrder,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to create a work order'); // Throw an error for non-200 status codes
    }
  } catch (error:any) {
    console.error('Error creating work order:', error?.response?.data);
    return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
  }
};

const update = async (newWorkOrder: WorkOrder) => {
  try {
    const workOrder = JSON.stringify(newWorkOrder);
    console.log(workOrder);

    const response = await httpWorkOrderService.put<WorkOrder>(
      `/workorder`,
      newWorkOrder,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to create a work order'); // Throw an error for non-200 status codes
    }
  } catch (error:any) {
    console.error('Error creating work order:', error?.response?.data);
    return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
  }
};

const deleteWO = async (id: string) => {
  try {
    // const workOrder = JSON.stringify(newWorkOrder);
    // console.log(workOrder);

    const response = await httpWorkOrderService.delete<string>(`/workorder/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to delete the work order'); // Throw an error for non-200 status codes
    }
  } catch (error:any) {
    console.error('Error deleting work order:', error?.response?.data);
    return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
  }
};



export const WorkOrderService = {
  getAll,
  getAllLegacy,
  getById,
  searchLabours,
  searchUnits,
  create,
  update,
  deleteWO,
  importAll
};