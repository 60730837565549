import { GoAButton } from "@abgov/react-components"

export const LoggedOut = () => {
  return (<>
    <div className="logout">
      <h1>Logged Out</h1>
      <p>You have successfully logged out from the IMIS Portal</p>
      <GoAButton onClick={() => window.location.href = window.location.origin}>Login</GoAButton>
    </div>
  </>)
}
