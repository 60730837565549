import {
  GoABlock,
  GoAButton,
  GoACheckbox,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAPagination,
  GoASpacer,
  GoATable,
  GoATableSortHeader,
} from "@abgov/react-components";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/searchInput";
import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { ItemLocation } from "../../model/Location";
import { debounce } from "@mui/material";
import Select, { InputActionMeta } from "react-select";
import { LocationService } from "../../services/LocationService";
import { downloadCSV } from "../../utils/ExportDataUtil";
import { SelectOptions } from "../../model/Base";
import PageLoader from "../../components/PageLoader";
import { permissions } from "../../common/constants";
import { hasResourceRole } from "../../utils/tokens";
import { useAuth } from "react-oidc-context";
import React from "react";
import CatalogItemDetailView from "../catalog/CatalogItemView";

const LocationSnapshot = () => {
  const [locations, setLocations] = useState<SelectOptions[]>([]);
  const [itemsData, setItems] = useState<ItemLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>();

  // Navigation
  const navigate = useNavigate();

  //Loader
  const [loading, setIsLoading] = useState(false);

  //Search
  const [searchValue, setSearchValue] = useState("");

  // page number
  const [page, setPage] = useState(1);

  //count per page
  const [perPage, setPerPage] = useState(25);

  const [totalCount, setTotalCount] = useState(0);

  //Sorting
  const [sortCol, setSortCol] = useState("name");
  const [sortDir, setSortDir] = useState(1);

  const [rollUp, setRollUp] = useState(false);

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null); // State to track expanded row

  const auth = useAuth();

  useEffect(() => {
    setItems([]);
    const fetchData = async () => {
      try {

        const loadItems = debounce(async (callback: (data: any) => void) => {
          setIsLoading(true);
          const data = await LocationService.getById(selectedLocation, searchValue, page, rollUp, perPage, sortCol, sortDir === 1 ? "ASC" : "DESC");
          setIsLoading(false);
          // Pass the transformed options to the callback function
          callback(data.data);
        }, 500);
        loadItems((result: any) => {
          setItems(result.items.filter((item: any) => item.name));
          setTotalCount(result?.pageInfo?.total)
        });

      } catch (error) {
        console.error('Error fetching location data:', error);
        setItems([]);
      }
      finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation) {
      fetchData();
    }
  }, [selectedLocation, searchValue, page, perPage, sortCol, sortDir, rollUp]);


  function search(searchParamValue: string) {
    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setPage(1);
      setSearchValue(searchParamValue);
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
    }
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);
  }

  function changePerPage(name: any, value: any) {
    if (value) {
      const newPerPage = parseInt(value, 10);
      setPerPage(newPerPage);
      setPage(page);
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    if (newPage) {
      setPerPage(perPage);
      setPage(newPage);
    }
  }

  const clearSearch = () => {
    setSearchValue("");
  };

  const onLocationSearchChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action === 'input-change') {
      loadLocationOptions(inputValue, (result: any) => {
        setLocations(result);
      });
    }
  }

  const loadLocationOptions = debounce(async (inputValue: string, callback: (data: any[]) => void) => {
    if (!inputValue) return;
    const data = await LocationService.getList(inputValue);
    // Pass the transformed options to the callback function
    callback(data);
  }, 500);


  const handleLocationChange = async (selectedLocation: any) => {
    setSelectedLocation(selectedLocation.value);
  }

  function handleRollupChange(name: string, checked: boolean) {
    setRollUp(checked)
  }

  const exportData = async () => {
    setIsLoading(true);
    const exportItemData = await LocationService.getById(selectedLocation, searchValue, page, rollUp, 2200, sortCol, sortDir === 1 ? "ASC" : "DESC");
    downloadCSV(exportItemData.data.items, `export-items-${selectedLocation}.csv`);
    setIsLoading(false);
  }

  const canExportData = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_LOCATION_EXPORT,
    auth.user?.access_token ?? ""
  );

  const toggleRowExpand = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  return (
    <>
      <PageLoader visible={loading} />
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>
              Home
            </GoAButton>
            <GoAButton type="tertiary" onClick={() => navigate('')}>
              Location snapshot
            </GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title="Location snapshot" />
          </div>
        </div>


        <div className='form-detail-container location'>
          <div className="form-row input-form-item">
            <div className="col-50 item-space">
              <GoAFormItem label='Location'>
                <Select
                  name="location"
                  options={locations}
                  placeholder="--Select--"
                  className="width100 select-input"
                  isDisabled={false}
                  isSearchable={true}
                  onInputChange={onLocationSearchChange}
                  onChange={handleLocationChange}
                />
              </GoAFormItem>
            </div>
          </div>
          <div className='input-form-item'>
            <GoAFormItem>
              <GoACheckbox name="rollUp" text="Rollup" onChange={handleRollupChange} checked={false}></GoACheckbox>
            </GoAFormItem>
          </div>
        </div>

        <div className="left width100" >
          <div className="left width-auto location-search">
            <GoAFormItem label='Search'>
              <SearchInput label="" search={search} clearSearch={clearSearch} placeholder="Search" />
            </GoAFormItem>
          </div>{canExportData && <div className="right width50 paddingLeft20px row-button">
            <GoAButton
              type="primary"
              disabled={itemsData.length === 0}
              trailingIcon="add"
              onClick={exportData}
            >
              Download data in CSV
            </GoAButton>
          </div>}

        </div>

        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="name">Name</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="imisId">IMIS ID</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="code">Part #</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="forEquipment">Use</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="serviceable">Serviceable</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="unserviceable">Unserviceable</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="unitPrice">Price</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="altPartNumber">Alt part #</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="min">Min</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="max">Max</GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="slotLocator">Storage location</GoATableSortHeader>
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {itemsData?.map((record: ItemLocation, index: any) => (
                <React.Fragment key={index}>
                  <tr key={record.id}>
                    <td>{record.name}</td>
                    <td>{record.imisId}</td>
                    <td>{record.code}</td>
                    <td>{record.use}</td>
                    <td>{record.serviceable}</td>
                    <td>{record.unserviceable}</td>
                    <td>{record.unitPrice}</td>
                    <td>{record.altPartNumber}</td>
                    <td>{record.min}</td>
                    <td>{record.max}</td>
                    <td>{record.slotLocator}</td>
                    <td>
                      <div className="flex">
                        <GoAButton
                          size="compact"
                          type="tertiary"
                          disabled={!record.id}
                          onClick={() => toggleRowExpand(index)}
                        >
                          <goa-icon type={(expandedRowIndex === index) ? "chevron-down" : "chevron-forward"}></goa-icon>
                        </GoAButton>
                      </div>
                    </td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={12}><CatalogItemDetailView id={record.catalogItemId} editable={false} /></td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </GoATable>
          <div className={totalCount > 0 ? "visible pagination pagination-div" : "not-visible pagination"}>
            <GoABlock alignment="center">
              <GoABlock mb="m" alignment="center" gap="m">
                <span style={{ whiteSpace: "nowrap" }}>Show</span>
                <div className="dropdown-list">
                  <GoADropdown name="selPerPage" onChange={changePerPage} value="25" width="8ch">
                    <GoADropdownItem value="25"></GoADropdownItem>
                    <GoADropdownItem value="50"></GoADropdownItem>
                    <GoADropdownItem value="100"></GoADropdownItem>
                  </GoADropdown>
                </div>
                <span style={{ whiteSpace: "nowrap" }}>of {totalCount} items</span>
              </GoABlock>
              <GoASpacer hSpacing="fill" />
              <GoAPagination
                itemCount={totalCount || 25}
                perPageCount={perPage}
                pageNumber={page}
                onChange={changePage}
              />
            </GoABlock>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationSnapshot;
