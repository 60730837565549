import React, { FunctionComponent, useState } from 'react';
import { GoABadge, GoAButton, GoAContainer, GoAFormItem } from '@abgov/react-components';
import PageLoader from '../../components/PageLoader';
import { useNavigate } from 'react-router-dom';
import { CatalogService } from '../../services/CatalogService';
import { Catalog } from '../../model/Catalog';
import moment from 'moment';
import { ForObjectType} from '../../model/enums/Constants';
import { CommonLookup } from '../../model/Common';
import { getDataFromSessionStorage } from '../../utils/SessionStorageUtils';
import { loadCommonLookups } from '../../states/Common.state';
import { useAuth } from 'react-oidc-context';
import { hasResourceRole } from '../../utils/tokens';
import { permissions } from '../../common/constants';

interface ICatalogItemDetailViewProps {
  id: string;
}

const CatalogView: FunctionComponent<ICatalogItemDetailViewProps> = ({ id }) => {
  const navigate = useNavigate();
  const [formState, setCatalog] = useState<Catalog>(); // Define formState as Partial<Catalog>
  const [loading, setIsLoading] = useState(false);
  const [catalogTypeOptions, setCatalogTypeOptions] = useState<{ id: number; value: string }[]>();

  React.useEffect(() => {
    async function fetchData() {
      if (id) {
        setIsLoading(true);
        try {
          const catalogResponse = await CatalogService.getById(id);
          setCatalog(catalogResponse.data);

          const lookupsResponse = getDataFromSessionStorage(ForObjectType.CATALOG);
          if (!lookupsResponse) {
            loadCommonLookups();
          }

          setCatalogTypeOptions(lookupsResponse.find((x: CommonLookup) => x.name === 'CatalogType')?.elements);

        } catch (error) {
          console.error("Error fetching catalog:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const auth = useAuth();
  const canCreateUpdateCatalog = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_CATALOG_WRITE,
    auth.user?.access_token ?? ""
  );


  return (
    <>
      <PageLoader visible={loading} />
      <GoAContainer accent="thin" padding="relaxed">
        <div className="form-details-container">

          <div className="form-details-row">
            <div className='input-form-item'>
              <GoAFormItem label='Name'>
                {formState?.name || ''}
              </GoAFormItem>
            </div>
            <div className='input-form-item'>
              <GoAFormItem label='Description'>
                {formState?.description || ''}
              </GoAFormItem>
            </div>
            <div className='input-form-item'>
              <GoAFormItem label='Category'>
                {catalogTypeOptions?.find(x => x.id === formState?.catalogType)?.value}
              </GoAFormItem>
            </div>
            <div className='input-form-item'>
              <GoAFormItem label='Status'>
                {formState?.status === 1 ? (
                  <GoABadge type="success" content="Active" />
                ) : formState?.status === 2 ? (
                  <GoABadge type="emergency" content="Inactive" />
                ) : (
                  <GoABadge type="important" content="Pending" />
                )}
              </GoAFormItem>
            </div>
            <div className='input-form-item'>
              <GoAFormItem label='Created'>
                {moment(formState?.auditTrail.createdDate).format("yyyy-MM-DD HH:mm:ss")}
              </GoAFormItem>
            </div>
            <div className='input-form-item'>
              <GoAFormItem label='Last updated'>
                {moment(formState?.auditTrail.lastUpdatedDate).format("yyyy-MM-DD HH:mm:ss")}
              </GoAFormItem>
            </div>
            <div className='input-form-item'>
              <GoAFormItem label='Created by'>
                {formState?.auditTrail.createdBy}
              </GoAFormItem>
            </div>
          </div>
        </div>

        {canCreateUpdateCatalog && <div className='align-right'><GoAButton type="tertiary" trailingIcon="pencil" onClick={() => navigate(`/catalogDetail/${formState?.id}`)}>
          Update
        </GoAButton></div>}
      </GoAContainer>
    </>
  );
};

export default CatalogView;
