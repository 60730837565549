
import { httpLocationService } from "../http-service";
import { ItemsLocationResponse, LocationKeyValuePair } from "../model/Location";
//import { Part } from "../model/Part";

const getById = async (
  locationId: string,
  search: string='',
  pageNumber: number=1,
  isRollUp:boolean=false,
  pageSize: number=5000,
  orderBy: string='name',
  sortDirection: string='1'
) => {
  const params = new URLSearchParams({
    locationId: locationId,
    search: search,
    isRollUp:String(isRollUp),
    pageNumber: String(pageNumber),
    pageSize: String(pageSize),
    orderBy: orderBy,
    sortDirection: sortDirection,
  });
  
  const data = await httpLocationService.get<ItemsLocationResponse>(`/items?${params}`);

  return data;
};

const getItemAdjustmentById = async (
  locationId: string,
  search: string='',
  pageNumber: number=1,
  isRollUp:boolean=false,
  pageSize: number=10,
  orderBy: string='name',
  sortDirection: string='ACS'
) => {
  const params = new URLSearchParams({
    locationId: locationId,
    search: search,
    isRollUp:String(isRollUp),
    pageNumber: String(pageNumber),
    pageSize: String(pageSize),
    orderBy: orderBy,
    sortDirection: sortDirection,
  });
  
  const data = await httpLocationService.get<ItemsLocationResponse>(`/itemAdjustment?${params}`);

  return data;
};
 

const getList = async (searchStr:string) => {

  const data = await httpLocationService.get<LocationKeyValuePair[]>(
    `location-list/${searchStr}`
  );

  const options = data?.data?.map(location => ({
    value: location.id,
    label: location.name
  }));
  return options.sort((a, b) => a.label.localeCompare(b.label));;;
};

export const LocationService = {
  getById,
  getItemAdjustmentById,
  getList 
};

