import {
  GoAButton,
  GoAButtonGroup,
  GoAModal,
  GoATab,
  GoATabs,
} from "@abgov/react-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import WorkOrderMainTab from "./tabs/WorkOrderMainTab";
import WorkOrderPartsAndLabourTab from "./tabs/WorkOrderPartsAndLabourTab";

const CreateWorkOrder: React.FunctionComponent = () => {
  // Navigation
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] =
  React.useState(false);
  const [formChanged, setFormChanged] = useState(false);

  //Loader
  // const [loading, setIsLoading] = React.useState(true);
  return (
    <>
      <div className="workorder-content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => window.location.pathname = '/home'}
            >
              Home
            </GoAButton>
            <GoAButton
              type="tertiary"
              trailingIcon="arrow-back"
              onClick={() => navigate("/workOrderList")}
            >
              Work orders
            </GoAButton>
            <GoAButton
              type="tertiary"
              onClick={() => navigate("/createWorkOrder")}
            >
              Create work order
            </GoAButton>
          </div>
        </div>
        <div className="left width100">
          <div className="left width-auto create-work-order-header">
            <PageHeader title={"Create work order"} />
          </div>
        </div>
      </div>
      <div className="workorder-tab-container">
        <GoATabs>
          <GoATab heading="Main">
            <WorkOrderMainTab formChanged={formChanged}  setFormChanged={setFormChanged} setShowCancelModal={setShowCancelModal} />
          </GoATab>
          <GoATab heading="Parts & Labour">
            <WorkOrderPartsAndLabourTab setFormChanged={setFormChanged} />
          </GoATab>
        </GoATabs>
      </div>
      <GoAModal heading="" open={showCancelModal} maxWidth="30vw"
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowCancelModal(false); }}>No</GoAButton>
            <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={() => {setShowCancelModal(false); navigate("/workOrderList");  }}>Yes</GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>Are you sure you want to cancel this workorder?</p>
      </GoAModal>
    </>
  );

};
export default CreateWorkOrder;
