import { AuditTrail, PageInfo, UserComment } from "./Base";
import { WorkOrderLabourItem } from "./WorkOrderLabour";
import { WorkOrderPartItem } from "./WorkOrderPart";

export interface IWorkOrder {
  division: string;
  isSelected: boolean | undefined;
  isImported: boolean | undefined;
  workOrderNo: string;
  status: number;
  statusDesc: string;
  chatchupDataEntry: boolean;
  nonCatalogItem: boolean;
  imiWorkOrderId: number;
  locationId: string;
  locationName: string;
  itemId: string | null;
  itemImisId: number | null;
  itemName: string;
  unitNo: string;
  altRefNo: string;
  make: string;
  model: string;
  serialNo: string;
  totalCostHistory: number | null;
  totalHoursHistory: number | null;
  description: string;
  comments: UserComment[] | undefined;
  contactName: string | null;
  contactPhone: string | null;
  expenditureOffice: string | null;
  chargeCode: string | null;
  costCentre: string | null;
  wbsNumber: string | null;
  glAccount: string | null;
  fund: string | null;
  partsCost: number | null;
  labourCost: number | null;
  shopSupplyPercent: number;
  shopSupplyCost: number;
  subletCost: number;
  subletDescription: string | null;
  totalCost: number;
  dateIn: Date | null;
  dateInString: string;
  createdByName: string | null;
  workedByUserId: string | null;
  isCertified: boolean;
  certifiedHours: number;
  certifiedPSI: number;
  dateOut: Date | null;
  dateOutString: string;
  closedByName: string | null;
  id?: string;
  auditTrail: AuditTrail | null; // Replace 'any' with appropriate type if known
  partItems: WorkOrderPartItem[] | undefined;
  labourItems: WorkOrderLabourItem[] | undefined;
  currentDateTime: string | null;
}

export class WorkOrder implements IWorkOrder {
  constructor(from?: Partial<WorkOrder>) {
    (this.division = from?.division ?? "");
    (this.isSelected = from?.isSelected ?? false);
    (this.isImported = from?.isImported ?? false);
    (this.workOrderNo = from?.workOrderNo ?? "");
      (this.status = from?.status ?? 1);
      (this.statusDesc = from?.statusDesc ?? "");
      (this.chatchupDataEntry = from?.chatchupDataEntry ?? false);
      (this.nonCatalogItem = from?.nonCatalogItem ?? false);
      (this.imiWorkOrderId = from?.imiWorkOrderId ?? 0);
      (this.locationId = from?.locationId ?? "");
      (this.locationName = from?.locationName ?? "");
      (this.itemId = from?.itemId ?? null);
      (this.itemImisId = from?.itemImisId ?? 0);
      (this.itemName = from?.itemName ?? "");
      (this.unitNo = from?.unitNo ?? "");
      (this.altRefNo = from?.altRefNo ?? "");
      (this.workOrderNo = from?.workOrderNo ?? "");
      (this.make = from?.make ?? "");
      (this.model = from?.model ?? "");
      (this.serialNo = from?.serialNo ?? "");
      (this.totalCostHistory = from?.totalCostHistory ?? 0);
      (this.totalHoursHistory = from?.totalHoursHistory ?? 0);
      (this.description = from?.description ?? "");
      (this.comments = from?.comments ?? []);
      (this.contactName = from?.contactName ?? "");
      (this.contactPhone = from?.contactPhone ?? "");
      (this.expenditureOffice = from?.expenditureOffice ?? "");
      (this.chargeCode = from?.chargeCode ?? "");
      (this.costCentre = from?.costCentre ?? "");
      (this.wbsNumber = from?.wbsNumber ?? "");
      (this.glAccount = from?.glAccount ?? "");
      (this.fund = from?.fund ?? "");
      (this.partsCost = from?.partsCost ?? 0);
      (this.labourCost = from?.labourCost ?? 0);
      (this.shopSupplyPercent = from?.shopSupplyPercent ?? 10);
      (this.shopSupplyCost = from?.shopSupplyCost ?? 0);
      (this.subletCost = from?.subletCost ?? 0);
      (this.subletDescription = from?.subletDescription ?? "");
      (this.totalCost = from?.totalCost ?? 0);
      (this.dateIn = from?.dateIn ?? new Date());
      (this.dateInString = from?.dateInString ?? "");
      (this.createdByName = "");
      (this.workedByUserId = from?.workedByUserId ?? "");
      (this.isCertified = from?.isCertified ?? false);
      (this.certifiedHours = from?.certifiedHours?? 0);
      (this.certifiedPSI = from?.certifiedPSI ?? 0);
      (this.dateOut = from?.dateOut ?? new Date());
      (this.dateOutString = from?.dateOutString ?? "");
      (this.closedByName = from?.closedByName ?? "");
      (this.id = from?.id ?? undefined);
      (this.auditTrail = from?.auditTrail ?? {
        createdBy: "",
        createdDate: new Date(),
        isDeleted: false,
        lastUpdatedBy: null,
        lastUpdatedDate: new Date(),
      });
      this.partItems = from?.partItems ?? [];
      this.labourItems = from?.labourItems ?? [];
      this.currentDateTime="";
  }
  division: string;
  isSelected: boolean | undefined;
  isImported: boolean | undefined;
  workOrderNo: string;
  status: number;
  statusDesc: string;
  chatchupDataEntry: boolean;
  nonCatalogItem: boolean;
  imiWorkOrderId: number;
  locationId: string;
  locationName: string;
  itemId: string | null;
  itemImisId: number | null;
  itemName: string;
  unitNo: string;
  altRefNo: string;
  make: string;
  model: string;
  serialNo: string;
  totalCostHistory: number | null;
  totalHoursHistory: number | null;
 // description: UserComment[] | undefined;
  description: string;
  comments: UserComment[] | undefined;
  contactName: string | null;
  contactPhone: string | null;
  expenditureOffice: string | null;
  chargeCode: string | null;
  costCentre: string | null;
  wbsNumber: string | null;
  glAccount: string | null;
  fund: string | null;
  partsCost: number | null;
  labourCost: number | null;
  shopSupplyPercent: number;
  shopSupplyCost: number;
  subletCost: number;
  subletDescription: string | null;
  totalCost: number;
  dateIn: Date;
  dateInString: string;
  createdByName: string | null;
  workedByUserId: string | null;
  isCertified: boolean;
  certifiedHours: number;
  certifiedPSI: number;
  dateOut: Date;
  dateOutString: string;
  closedByName: string | null;
  id?: string | undefined;
  auditTrail: AuditTrail | null;
  partItems: WorkOrderPartItem[];
  labourItems: WorkOrderLabourItem[];
  currentDateTime: string | null;
}

export interface WorkOrderResponse {
  workOrders: WorkOrder[];
  pageInfo: PageInfo;
}

export interface WorkOrderRequest {
  workOrders: WorkOrder[];
}
