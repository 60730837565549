
import { WorkOrder } from "./WorkOrder";

  export interface IWorkOrderPdf {
    operation: string, 
    templateId: string, 
    filename: string,
    data : WorkOrder | undefined
  }  


export class WorkOrderPdf implements IWorkOrderPdf {
  constructor(from?: Partial<WorkOrderPdf>) {
    (this.operation = from?.operation ?? "generate");
    (this.templateId = from?.templateId ?? "");
    (this.filename = from?.filename ?? "");
    (this.data = from?.data ?? undefined);
  }
  operation: string;
  templateId: string;
  filename: string;
  data : WorkOrder | undefined;
}
