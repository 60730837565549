 
  type FlatObject = { [key: string]: any };

  const flattenObject = (obj: any, parentKey = '', res: FlatObject = {}): FlatObject => {
      for (let key in obj) {
          const propName = parentKey ? `${parentKey}.${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
              flattenObject(obj[key], propName, res);
          } else {
              res[propName] = obj[key];
          }
      }
      return res;
  };

  const convertToCSV = (data: any[]): string => {
  const flattenedData = data.map(item => flattenObject(item));
  const keys = Object.keys(flattenedData[0]);
  const csvRows = [
    keys.join(','), // header row
    ...flattenedData.map(row => keys.map(key => row[key]).join(',')) // data rows
  ];
  return csvRows.join('\n');
};

// Function to download data as a CSV file
export const downloadCSV = (data: any[], filename: string) => {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};