import { AuditTrail, IPageInfo, UserComment } from "./Base";
export class Adjustments {
  adjustments: Adjustment[] = [];
  pageInfo: IPageInfo | undefined;
}

export class Adjustment {
    constructor(
        from?: Partial<Adjustment>
    ) {
        this.adjustmentNo = from?.adjustmentNo ?? "";
        this.locationId = from?.locationId ?? "";
        this.locationName=from?.locationName??"";
        this.fireNo = from?.fireNo ?? "";
        this.comments = from?.comments ?? [];
        this.items = from?.items ?? [];
        this.isConsumable = from?.isConsumable ?? false;
        this.id = from?.id ?? "00000000-0000-0000-0000-000000000000";
        this.status = from?.status ?? 0;
        this.reason = from?.reason ?? "";
        this.auditTrail = from?.auditTrail ?? {
            createdBy: "",
            createdDate: new Date(),
            isDeleted: false,
            lastUpdatedBy: null,
            lastUpdatedDate: new Date(),
        };
    }

    adjustmentNo: string;
    locationId: string;
    locationName:string;
    fireNo: string;
    comments: UserComment[];
    items: AdjustmentItem[];
    isConsumable: boolean;
    id: string;
    status: number;
    reason: string;
    auditTrail: AuditTrail;
    pageInfo: IPageInfo | undefined;
}

export interface IAdjustmentItem {
    isChecked: boolean;
    locationItemId: string;
    catalogItemId:string;
    name: string;
    code: string | undefined;
    imisId: number;
    status: number | undefined;
    qtyCurrent: number;
    qtyAdjustment: number;
    qtyAdjusted: number;
    isConsumable: boolean;
    owner: number | undefined;
    unitPrice: number | undefined; 
    totalPrice: number | undefined;
    isUsedPart: boolean;
  }
  
  export class AdjustmentItem implements IAdjustmentItem {
    constructor(
      public isChecked: boolean = false,
      public locationItemId: string = '',
      public catalogItemId:string='',
      public name: string = '',
      public code: string  | undefined = '',
      public imisId: number = 0,
      public status: number | undefined =0,
      public qtyCurrent: number = 0,
      public qtyAdjustment: number = 0,
      public qtyAdjusted: number = 0,
      public isConsumable: boolean = false,
      public owner: number | undefined = 0,
      public unitPrice: number | undefined = 0,
      public totalPrice: number | undefined = 0,
      public isUsedPart: boolean = false
    ) {}
  }
  
  
