import {
  GoAButton,
  GoACheckbox,
  GoAContainer,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAInput,
  GoATable,
} from "@abgov/react-components";
import { useEffect, useState } from "react";
import { WorkOrder } from "../../../model/WorkOrder";
//import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { LocationService } from "../../../services/LocationService";
import { ItemLocation } from "../../../model/Location";
//import PageLoader from "../../../components/PageLoader";
import Select, { InputActionMeta } from "react-select";
import { useRecoilState } from "recoil";
import { labourItemsFilteredState, labourItemsUnusedState, partItemsFilteredState, partItemsUnusedState, partsLocationSelectedState, partsLocationState, workOrderState } from "../../../states/WorkOrder.state";
import { WorkOrderLabourItem } from "../../../model/WorkOrderLabour";
import { WorkOrderService } from "../../../services/WorkOrderService";
import { WorkOrderPartItem } from "../../../model/WorkOrderPart";
import { SelectOptions } from "../../../model/Base";
import { hasResourceRole } from "../../../utils/tokens";
import { permissions, WORKORDER_STATUS_COMPLETED } from "../../../common/constants";
import { useAuth } from "react-oidc-context";
import { mapOTFactorDesc } from "../../../common/functions";

interface IWorkOrderPartsAndLabourTabProps {
  workOrderId?: string;
  setFormChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkOrderPartsAndLabourTab: React.FC<IWorkOrderPartsAndLabourTabProps> = (props) => {
  const { setFormChanged } = props;
  const [formWorkOrder, setWorkOrder] = useRecoilState<WorkOrder>(workOrderState);
  const [partsLocationOptions, setPartsLocationOptions] =
    useRecoilState<SelectOptions[]>(partsLocationState);
  const [selectedPartsLocation, setSelectedPartsLocation] =
    useRecoilState<SelectOptions>(partsLocationSelectedState);
  const [checkAllParts, setCheckAllParts] = useState<boolean>(false);
  const [checkAllLabours, setCheckAllLabours] = useState<boolean>(false);
  const [itemsOptions, setItems] = useState<ItemLocation[]>([]);

  //const [locations, setLocations] = useState<SelectOptions[]>([]);
  //const [selectedLocation, setSelectedLocation] = useState<any>();
  // const [partItemOptionsFiltered, setPartItemsFiltered] = useRecoilState<any[]>(partItemsFilteredState);
  // const [unUsedItemsOptionsFiltered, setUnusedItemsFiltered] = useState<any[]>(
  //   []
  // );

  const [partItemOptionsFiltered, setPartItemOptionsFiltered] = useRecoilState<any[]>(
    partItemsFilteredState
  );
  const [partItemOptionsUnused, setPartItemOptionsUnused] = useRecoilState<any[]>(
    partItemsUnusedState
  );

  const [labourItemOptionsFiltered, setLabourItemOptionsFiltered] = useRecoilState<any[]>(
    labourItemsFilteredState
  );

  const [labourItemOptionsUnused, setLabourItemOptionsUnused] = useRecoilState<any[]>(
    labourItemsUnusedState
  );

  const [labourItems, setLabourItems] = useState<WorkOrderLabourItem[]>([]);


  const handleFieldChange = async (name: string, value: any, index: number = -1) => {
    setFormChanged(true);
    if (index >= 0 && formWorkOrder?.partItems && formWorkOrder?.partItems[index]) {
      const updatedPartItems = [...(formWorkOrder?.partItems ?? [])];
      if (name === 'quantity') {
        const unitPrice = updatedPartItems[index].unitCost;
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          quantity: value,
          partItemCost: (value) * (unitPrice ?? 0)
        }
      }
      if (name === 'unitCost') {
        const quantity = updatedPartItems[index].quantity;
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          unitCost: value,
          partItemCost: (value) * (quantity ?? 0)
        }
      }
      if (name === 'isUsedPart') {
        var itemUnitCost;
        if (!value) {
          const itemsData = await LocationService.getById(selectedPartsLocation.value, updatedPartItems[index].itemName)
          itemUnitCost = itemsData?.data?.items?.find((l: ItemLocation) => l.catalogItemId === updatedPartItems[index].catalogItemId)?.unitPrice
        }
        updatedPartItems[index] = {
          ...updatedPartItems[index],
          isUsedPart: value,
          unitCost: value ? 0 : itemUnitCost,
          partItemCost: value ? 0 : (updatedPartItems[index]?.quantity ?? 0) * (itemUnitCost ?? 0)
        }
      }

      const finalPartsCost = updatedPartItems?.filter(i => !i.isDeleted)?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0)
      }, 0);
      const shopSupplyCost = (finalPartsCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
      const totalCost = (finalPartsCost ?? 0) + (formWorkOrder.labourCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;

      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedPartItems,
        partsCost: finalPartsCost ?? 0,
        shopSupplyCost: shopSupplyCost,
        totalCost: totalCost
      });
    }
    //  setValue({ [name]: value });
    //validateForm(name, value);
  };

  const loadLocationOptions = debounce(
    async (inputValue: string, callback: (data: any[]) => void) => {
      if (!inputValue) return;
      const data = await LocationService.getList(inputValue);
      // Pass the transformed options to the callback function
      callback(data);
    },
    500
  );

  useEffect(() => {
    if (!partsLocationOptions) {
      loadLocationOptions('WSC1SE MECHANICAL PARTS ROOM', (result) => {
        setPartsLocationOptions(result);
        setSelectedPartsLocation(result[0]);
      });
    }
  }, [loadLocationOptions, setPartsLocationOptions, setSelectedPartsLocation, partsLocationOptions]);

  function setCheckAllPartItems(checked: boolean) {
    setCheckAllParts(true);
    const updatedItems = formWorkOrder?.partItems?.map((record: WorkOrderPartItem) => ({
      ...record,
      isChecked: checked
    }));

    if (formWorkOrder) {
      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedItems,
      });
    }
  }

  function setCheckAllLabourItems(checked: boolean) {
    setCheckAllLabours(true);
    const updatedItems = formWorkOrder?.labourItems?.map((record: WorkOrderLabourItem) => ({
      ...record,
      isChecked: checked
    }));

    if (formWorkOrder) {
      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedItems,
      });
    }
  }

  function handleAddPartItemClick(): void {
    setPartItemOptionsUnused([]);

    if (formWorkOrder) {
      const newItem = new WorkOrderPartItem();

      // Spread the existing items and add the new item
      const updatedItems = [...(formWorkOrder.partItems || []), newItem];

      // Update the form state with the new items array
      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedItems,
      });

      //validateForm('add-item');
    }
  }

  function handleAddLabourItemClick(): void {
    setLabourItemOptionsUnused([]);
    if (formWorkOrder) {

      const newItem = new WorkOrderLabourItem();
      // Spread the existing items and add the new item
      const updatedItems = [...(formWorkOrder.labourItems || []), newItem];

      // Update the form state with the new items array
      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedItems,
      });
    }
  }

  const handlePartItemChange = (item: any, index: number) => {
    setFormChanged(true);
    const selectedItem = itemsOptions.find(
      (l: ItemLocation) => l.catalogItemId === item.value
    );
    const updatedFormState = [...(formWorkOrder?.partItems ?? [])];
    const quantity = 1;
    updatedFormState[index] = {
      ...updatedFormState[index],
      partItemCost:quantity*(selectedItem?.unitPrice ?? 0),
      catalogItemId: item.value,
      locationItemId: selectedItem?.id ?? "",
      //quantityOnHand: selectedItem?.serviceable??0,
      quantity: quantity,
      partNumber: selectedItem?.code ?? "",
      itemName: selectedItem?.name ?? "",
      unitCost: selectedItem?.unitPrice ?? 0,
      locationId: selectedItem?.locationId ?? "",
      locationName: selectedPartsLocation.label,
      isUsedPart: false,
    };

    const finalPartsCost = updatedFormState.filter(i => !i.isDeleted)?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
      return (accumulator ?? 0) + (currentValue ?? 0)
    }, 0);
    const shopSupplyCost = (finalPartsCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
    const totalCost = (finalPartsCost ?? 0) + (formWorkOrder.labourCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;
     
  

    // Update the state with the modified array
    if (formWorkOrder) {
      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedFormState,
        partsCost: finalPartsCost ?? 0,
        shopSupplyCost: shopSupplyCost,
        totalCost: totalCost
      });
    }
  };

  const finalPartsCost = formWorkOrder?.partItems.filter(i => !i.isDeleted)?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
    return (accumulator ?? 0) + (currentValue ?? 0)
  }, 0);

  const finalLabourCost = formWorkOrder?.labourItems?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
    return (accumulator ?? 0) + (currentValue ?? 0)
  }, 0);

  const onPartItemSearchChange = (inputValue: string, { action }: InputActionMeta) => {
    if (!selectedPartsLocation)
      return;

    if (action === 'input-change') {
      inputValue = inputValue.toLowerCase();
      if (inputValue.length > 1) {
        getItemsForLocation(selectedPartsLocation.value, inputValue);

        const data = itemsOptions
          .filter(x =>
            (x.code && x.code.includes(inputValue)) ||
            (x.name && x.name.toLowerCase().includes(inputValue)) ||
            (x.imisId && x.imisId.toString().includes(inputValue))
          )
          .sort((a, b) => b.name.localeCompare(a.name));
        if (data) {
          populateItems(data);
        }
      }
    }
  };

  const onLocationSearchChange = (
    inputValue: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change") {
      loadLocationOptions(inputValue, (result: any) => {
        setPartsLocationOptions(result);
      });
    }
  };

  const handleLocationChange = (selectedPartsLocation: any) => {
    // setSelectedLocation(selectedLocation);
    setSelectedPartsLocation(selectedPartsLocation);
    const updatedPartItems = [...(formWorkOrder?.partItems ?? [])];
    updatedPartItems?.forEach((value, index) => {
      updatedPartItems[index] = {
        ...updatedPartItems[index],
        locationId: selectedPartsLocation.value,
        locationName: selectedPartsLocation.label,
      }
    });

    setWorkOrder({
      ...formWorkOrder,
      partItems: updatedPartItems
    });
  };

  const getItemsForLocation = async (locationId: string, search: string) => {
    if (!locationId)
      return;
    const itemsData = await LocationService.getById(locationId, search);
    setItems(itemsData?.data.items);
    if (itemsData && itemsData.data) {
      populateItems(itemsData.data.items);
    }
  }

  const onLabourCodeChange = (
    inputValue: string,
    { action }: InputActionMeta
  ) => {
    if (action === "input-change") {
      loadLabourCodesOptions('', (data: any) => {
        populateLabourOptionUsedItems(data.labours);
      });

      loadLabourCodesOptions(inputValue, (data: any) => {
        populateLabourOptionUnUsedItems(data.labours);
        const newItems = data.labours.filter((i: any) =>
          !formWorkOrder.labourItems?.some(
            currentSelected => currentSelected.labourItemId === i.labourItemId
          )
        );
        setLabourItems(newItems);
      });
    }
  };

  const loadLabourCodesOptions = debounce(
    async (inputValue: string = '', callback: (data: any[]) => void) => {
      const data = await WorkOrderService.searchLabours(inputValue);
      // Pass the transformed options to the callback function
      callback(data);
    },
    500
  );



  const populateItems = (data: ItemLocation[]) => {

    const options: SelectOptions[] = data
      .map(item => {
        if (item.name !== null) {
          return {
            value: item.catalogItemId,
            label: `${item.name} (${item.imisId}-${item.code})`
          };
        }
        return null;
      })
      .filter(option => option !== null) as SelectOptions[];

    const unUsedOptions: SelectOptions[] = data
      .filter(item => item.name !== null && !formWorkOrder.partItems?.some(formItem => formItem.catalogItemId === item.catalogItemId))
      .map(item => ({
        value: item.catalogItemId,
        label: `${item.name} (${item.imisId}-${item.code})`
      }));
    setPartItemOptionsFiltered(options);
    setPartItemOptionsUnused(unUsedOptions);
  };

  const populateLabourOptionUsedItems = (data: WorkOrderLabourItem[]) => {
    const options: SelectOptions[] = data
      .map(item => {
        if (item.serviceCode !== null) {
          return {
            value: item.labourItemId,
            label: `${item.serviceCode}`
          };
        }
        return null;
      })
      .filter(option => option !== null) as SelectOptions[];

    setLabourItemOptionsFiltered(options);
  };

  const populateLabourOptionUnUsedItems = (data: WorkOrderLabourItem[]) => {
    const unUsedOptions: SelectOptions[] = data
      .filter(item => item.serviceCode !== null && !formWorkOrder.labourItems?.some(formItem => formItem.labourItemId === item.labourItemId))
      .map(item => ({
        value: item.labourItemId,
        label: `${item.description} - ${item.serviceCode}`
      }));

    setLabourItemOptionsUnused(unUsedOptions);
  };

  function deletePartItem() {
    // Update form change state
    // setFormChanged(true);
    setCheckAllParts(false);

    if (formWorkOrder) {
      // Filter out the items that are checked
      const activeItems = formWorkOrder.partItems?.filter(item => !item.isChecked);
      const finalPartsCost = activeItems?.map(i => i.partItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0)
      }, 0);
      const shopSupplyCost = (finalPartsCost ?? 0) * (formWorkOrder.shopSupplyPercent / 100);
      const totalCost = (finalPartsCost ?? 0) + (formWorkOrder.labourCost ?? 0) + (formWorkOrder.subletCost ?? 0) + shopSupplyCost;

      //mark checked items as deleted
      const updatedItems = [...formWorkOrder.partItems ?? []];

      updatedItems.forEach((item, index, arr) => {
        arr[index] = {
          ...arr[index],
          isDeleted: item.isChecked
        }
      });

      // Update the state with the modified items array
      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedItems,
        partsCost: finalPartsCost ?? 0,
        shopSupplyCost: shopSupplyCost,
        totalCost: totalCost
      });
    }
  }

  function deleteLabourItem() {
    setFormChanged(true);
    setCheckAllLabours(false);

    if (formWorkOrder) {
      // Filter out the items that are checked
      const activeItems = formWorkOrder.labourItems?.filter(item => !item.isChecked);

      const finalLabourCost = activeItems?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0);
      }, 0);
      const totalCost = (finalLabourCost ?? 0) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + formWorkOrder.shopSupplyCost;

       //mark checked items as deleted
       const updatedItems = [...formWorkOrder.labourItems ?? []];

       updatedItems.forEach((item, index, arr) => {
         arr[index] = {
           ...arr[index],
           isDeleted: item.isChecked
         }
       });

      // Update the state with the modified items array
      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedItems,
        labourCost: finalLabourCost ?? 0,
        totalCost: totalCost
      });

      // Update labour item options
      loadLabourCodesOptions('', (data: any) => {
        populateLabourOptionUsedItems(data.labours);
        populateLabourOptionUnUsedItems(data.labours);
      });
    }
  }


  function setCheckPartItem(checked: boolean, index: number): void {
    //setFormChanged(true);
    if (formWorkOrder) {
      const updatedPartItems = [...formWorkOrder.partItems ?? []];
      updatedPartItems[index] = {
        ...updatedPartItems[index],
        isChecked: checked
      }

      setWorkOrder({
        ...formWorkOrder,
        partItems: updatedPartItems
      });
    }
  }

  function setCheckLabourItem(checked: boolean, index: number): void {
    //setFormChanged(true);
    if (formWorkOrder) {
      const updatedLabourItems = [...formWorkOrder.labourItems ?? []];
      updatedLabourItems[index] = {
        ...updatedLabourItems[index],
        isChecked: checked
      }

      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedLabourItems
      });
    }
  }

  const handleOtFactorChange = (otFactor: any, index: number) => {
    const updatedFormState = [...formWorkOrder?.labourItems];

    updatedFormState[index] = {
      ...updatedFormState[index],
      otFactor: otFactor,
      otFactorDesc: mapOTFactorDesc(otFactor),
      labourItemCost: (updatedFormState[index]?.hours ?? 0) * (updatedFormState[index]?.hourlyRate ?? 38) * otFactor
    };

    const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
      return (accumulator ?? 0) + (currentValue ?? 0)
    }, 0) ?? 0;
    
    const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + formWorkOrder.shopSupplyCost;
    
    // Update the state with the modified array
    setWorkOrder({
      ...formWorkOrder,
      labourItems: updatedFormState,
      labourCost: finalLabourCost,
      totalCost: totalCost
    });
  }

  const handleLabourCodeChange = (item: any, index: number) => {
    if (formWorkOrder.labourItems?.find(i => i.labourItemId !== item.value)) {
      const selectedLabour = labourItems.find(l => l.labourItemId === item.value);
      const updatedFormState = [...formWorkOrder?.labourItems];
      updatedFormState[index] = {
        ...updatedFormState[index],
        labourItemId: selectedLabour?.labourItemId ?? '',
        serviceCode: selectedLabour?.serviceCode ?? '',
        description: selectedLabour?.description ?? '',
        hours: selectedLabour?.hours ?? 0,
        labourItemCost: (selectedLabour?.hours ?? 0) * (selectedLabour?.hourlyRate ?? 38) * (selectedLabour?.otFactor ?? 1)
      };

      const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
        return (accumulator ?? 0) + (currentValue ?? 0)
      }, 0) ?? 0;
      
      const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + formWorkOrder.shopSupplyCost;
      
      // Update the state with the modified array
      setWorkOrder({
        ...formWorkOrder,
        labourItems: updatedFormState,
        labourCost: finalLabourCost,
        totalCost: totalCost
      });
    }
  }

  const handleRateChange = (hourlyRate: number, index: number) => {
    const updatedFormState = [...formWorkOrder?.labourItems];

    updatedFormState[index] = {
      ...updatedFormState[index],
      hourlyRate: hourlyRate,
      labourItemCost: (updatedFormState[index]?.hours ?? 0) * hourlyRate * (updatedFormState[index]?.otFactor ?? 1)
    };

    const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
      return (accumulator ?? 0) + (currentValue ?? 0)
    }, 0) ?? 0;
    
    const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + formWorkOrder.shopSupplyCost;
    
    // Update the state with the modified array
    setWorkOrder({
      ...formWorkOrder,
      labourItems: updatedFormState,
      labourCost: finalLabourCost,
      totalCost: totalCost
    });
  }

  function handleHoursChange(hourVal: string, index: number) {
    const updatedFormState = [...formWorkOrder?.labourItems];

    updatedFormState[index] = {
      ...updatedFormState[index],
      hours: Number(hourVal),
      labourItemCost: Number(hourVal) * (updatedFormState[index]?.hourlyRate ?? 38) * (updatedFormState[index]?.otFactor ?? 1),
    };

    const finalLabourCost = updatedFormState?.filter(i => !i.isDeleted)?.map(i => i.labourItemCost).reduce((accumulator, currentValue) => {
      return (accumulator ?? 0) + (currentValue ?? 0)
    }, 0) ?? 0;
    
    const totalCost = (finalLabourCost) + (formWorkOrder.partsCost ?? 0) + (formWorkOrder.subletCost ?? 0) + formWorkOrder.shopSupplyCost;
    
    // Update the state with the modified array
    setWorkOrder({
      ...formWorkOrder,
      labourItems: updatedFormState,
      labourCost: finalLabourCost,
      totalCost: totalCost
    });
  }

  const auth = useAuth();
  const canUpdateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_UPDATE,
    auth.user?.access_token ?? ""
  );

  const canPartiallyUpdateWorkOrder = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_WORKORDER_UPDATE_MECHANIC,
    auth.user?.access_token ?? ""
  );

  return (
    <>
      {/* <PageLoader visible={loading} /> */}
      <div className={`workorder-parts-labour-tab-wrapper ${formWorkOrder.status === WORKORDER_STATUS_COMPLETED ? 'disabled' : ''}`}>
        {" "}
        <GoAContainer accent="thick" padding="relaxed" title="Parts">
          <div className={`workorder-parts-container ${canUpdateWorkOrder || canPartiallyUpdateWorkOrder ? '' : 'disable'}`}>
            <div>
              <GoAFormItem label="Parts location">
                <Select
                  name="location"
                  options={partsLocationOptions}
                  placeholder="--Select--"
                  className="width100 select-input"
                  isDisabled={false}
                  isSearchable={true}
                  onInputChange={onLocationSearchChange}
                  onChange={handleLocationChange}
                  value={
                    formWorkOrder?.partItems?.[0]?.locationId
                      ? partsLocationOptions?.find(i => i.value === formWorkOrder.partItems[0].locationId)
                      : partsLocationOptions?.find(i => i.label === 'WSC1SE MECHANICAL PARTS ROOM')
                  }
                />

              </GoAFormItem>
            </div>
            <div
              style={{
                marginLeft: "-15px",
                marginTop: "30px",
                marginBottom: "-10px",
              }}
            >
              <GoAButton
                type="tertiary"
                disabled={formWorkOrder?.partItems?.length === 0}
                onClick={deletePartItem}
              >
                Delete item(s)
              </GoAButton>
            </div>
            <div>
              <div className="input-form-item">
                <GoAFormItem>
                  {/* error={errors["items"]}> */}
                  <GoATable width="100%">
                    <thead>
                      <tr>
                        <th className="check-item">
                          <GoACheckbox
                            name="check"
                            text=""
                            checked={checkAllParts}
                            onChange={(name, checked, value) => {
                              setCheckAllPartItems(checked);
                            }}
                          />
                        </th>
                        <th>Item name</th>
                        {/* <th>Qty on hand</th> */}
                        <th>Qty</th>
                        <th>Part number</th>
                        <th>Used part</th>
                        <th>Unit cost</th>
                        <th>Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formWorkOrder?.partItems?.map(
                        (record: WorkOrderPartItem, index: any) => (
                          !record.isDeleted &&
                          (<tr key={index}>
                            <td className="check-item">
                              <GoACheckbox
                                name="isChecked"
                                text=""
                                value={record.isChecked}
                                checked={record.isChecked}
                                onChange={(name, checked, value) => {
                                  setCheckPartItem(checked, index);
                                }}
                              />
                            </td>

                            <td>
                              <GoAFormItem
                              //error={errors[`catalogItemId-${index}`]}
                              >
                                <Select
                                  name="itemName"
                              
                                  options={
                                    record.catalogItemId
                                      ? partItemOptionsFiltered
                                      : partItemOptionsUnused
                                  }
                                  placeholder="--Select--"
                                  className="width100 select-input select-item"
                                  isDisabled={false}
                                  isSearchable={true}
                                  onInputChange={onPartItemSearchChange}
                                  value={partItemOptionsFiltered?.find(
                                    (x) => x.value === record.catalogItemId
                                  )}
                                  onChange={(item) =>
                                    handlePartItemChange(item, index)
                                  }
                                  
                                />
                              </GoAFormItem>
                            </td>
                            {/* <td> */}
                            {/* {record.quantityOnHand} */}
                            {/* </td> */}
                            <td>
                              <GoAInput
                                name="qty"
                                width="150px"
                                type="number"
                                min={1}
                                value={record.quantity?.toString() ?? "1"}
                                onChange={(item, value) =>
                                  handleFieldChange("quantity", value, index)
                                }
                              ></GoAInput>
                            </td>
                            <td>
                              <GoAInput
                                name="partNumber"
                                 width="150px"
                                value={record?.partNumber ?? ""}
                                onChange={(item, value) =>
                                  handleFieldChange("partNumber", value, index)
                                }
                              ></GoAInput>
                            </td>
                            <td className="centered-cell">
                              <GoAFormItem>
                                <GoACheckbox
                                  name="usedPart"
                                  text="Used part"
                                  checked={record.isUsedPart}
                                  onChange={(item, checked, value) =>
                                    handleFieldChange(
                                      "isUsedPart",
                                      checked,
                                      index
                                    )
                                  }
                                />
                              </GoAFormItem>
                            </td>
                            <td>
                              <GoAInput
                                leadingContent="$"
                                width="150px"
                                type="number"
                                name="unitCost"
                                value={record?.unitCost?.toString() ?? ""}
                                onChange={(item, value) =>
                                  handleFieldChange("unitCost", value, index)
                                }
                              ></GoAInput>
                            </td>
                            <td>
                              ${record?.partItemCost?.toFixed(2) ?? ""}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </GoATable>
                </GoAFormItem>
              </div>

              <div className="input-form-item">
                <div className="left">
                  <GoAButton type="secondary" onClick={handleAddPartItemClick} disabled={selectedPartsLocation === undefined}>
                    Add row
                  </GoAButton>
                </div>
                <div className="right">
                  <b>Parts cost: </b>
                  ${finalPartsCost?.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </GoAContainer>
        <GoAContainer accent="thick" padding="relaxed" title="Labour">
          <div className={`workorder-labour-container ${canUpdateWorkOrder || canPartiallyUpdateWorkOrder ? '' : 'disable'}`}>
            <div
              style={{
                marginLeft: "-15px",
                marginTop: "30px",
                marginBottom: "-10px",
              }}
            >
              <GoAButton
                type="tertiary"
                disabled={formWorkOrder?.labourItems?.length === 0}
                onClick={deleteLabourItem}
              >
                Delete item(s)
              </GoAButton>
            </div>
            <div>
              <div className="input-form-item">
                <GoAFormItem>
                  {/* error={errors["items"]}> */}
                  <GoATable width="100%">
                    <thead>
                      <tr>
                        <th className="check-item">
                          <GoACheckbox
                            name="check"
                            text=""
                            checked={checkAllLabours}
                            onChange={(name, checked, value) => {
                              setCheckAllLabourItems(checked);
                            }}
                          />
                        </th>
                        <th>Service code</th>
                        <th>Description</th>
                        <th>Hours</th>
                        <th>OT factor</th>
                        <th>Hourly rate</th>
                        <th>Total cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formWorkOrder?.labourItems?.map(
                        (record: WorkOrderLabourItem, index: any) => (
                         !record.isDeleted && 
                          (<tr key={index}>
                            <td className="check-item" style={{ width: '3%' }}>
                              <GoACheckbox
                                name="isChecked"
                                text=""
                                value={record.isChecked}
                                checked={record.isChecked}
                                onChange={(name, checked, value) => {
                                  setCheckLabourItem(checked, index);
                                }}
                              />
                            </td>
                            <td className="width40">
                              <Select
                                name="labourServiceCode"
                                options={
                                  record.labourItemId
                                    ? labourItemOptionsFiltered
                                    : labourItemOptionsUnused
                                }
                                placeholder="--Select--"
                                className="width100 select-input"
                                isDisabled={false}
                                isSearchable={true}
                                onInputChange={onLabourCodeChange}
                                onChange={(item) => handleLabourCodeChange(item, index)}
                                value={labourItemOptionsFiltered
                                  ?.map((item) => ({
                                    ...item,
                                    label: `${item.label}`,
                                  }))
                                  .find((i) => i.value === record.labourItemId)}
                              />


                            </td>
                            <td className="width20">
                              <GoAInput width="100%"
                                name="description"
                                value={record?.description ?? ""}
                                onChange={(item, value) =>
                                  handleFieldChange("name", value, index)
                                }
                              ></GoAInput>
                            </td>
                            <td className="width10">
                              <GoAFormItem>
                                <input
                                  type="number"
                                  onChange={(e) => { handleHoursChange(e.currentTarget.value, index); }}
                                  value={record.hours?.toString() ?? "00"}
                                  className={"input-common"}
                                  name="hours"
                                />
                              </GoAFormItem>
                            </td>
                            <td className="width10">
                              <GoADropdown name="otFactor" value={record.otFactor?.toString()} onChange={(name, value) => handleOtFactorChange(value, index)}>
                                <GoADropdownItem value="1" label={mapOTFactorDesc(1)}></GoADropdownItem>
                                <GoADropdownItem value="1.5" label={mapOTFactorDesc(1.5)}></GoADropdownItem>
                                <GoADropdownItem value="2" label={mapOTFactorDesc(2)}></GoADropdownItem>
                              </GoADropdown>
                            </td>
                            <td className="width10">
                              <input
                                disabled={!canUpdateWorkOrder}
                                type="number"
                                onChange={(e) => {
                                  handleRateChange(
                                    Number(e.currentTarget.value),
                                    index
                                  );
                                }}
                                value={record.hourlyRate?.toString() ?? "38"}
                                className={"input-common"}
                                width="131px"
                                name="hourlyRate"
                              />
                            </td>
                            <td>${record.labourItemCost?.toFixed(2)}</td>
                          </tr>
                              
                        ))
                      )}
                    </tbody>
                  </GoATable>
                </GoAFormItem>
              </div>

              <div className="input-form-item">
                <div className="left">
                  <GoAButton type="secondary" onClick={handleAddLabourItemClick}>
                    Add row
                  </GoAButton>
                </div>
                <div className="right">
                  <b>Labour cost: </b>
                  ${finalLabourCost?.toFixed(2) ?? ""}
                </div>
              </div>
            </div>
          </div>
        </GoAContainer>
      </div>
    </>
  );
};
export default WorkOrderPartsAndLabourTab;
