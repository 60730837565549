import { httpInventoryService } from "../http-service";
import { Adjustment, Adjustments } from "../model/Adjustments";

const getAdjustmentById = async (id: string) => {
  const data = await httpInventoryService.get<Adjustment>(
    `/adjustment/${id}`
  );

  return data;
};

const getAdjustments = async (
  search: string,
  pageNumber: number,
  pageSize: number,
  orderBy: string,
  sortDirection: string,
  startDate?: Date,
  endDate?: Date
) => {
  const params = new URLSearchParams({
    search: search,
    pageNumber: String(pageNumber),
    pageSize: String(pageSize),
    orderBy: orderBy,
    sortDirection: sortDirection,
  });

  if (startDate) {
    params.append('startDate', startDate.toDateString());
  }

  if (endDate) {
    params.append('endDate', endDate.toDateString());
  }

  const data = await httpInventoryService.get<Adjustments>(`/adjustment?${params}`);

  return data;
};



const create = async (newAdjustment: Adjustment) => {
  try {
    const adjustment = JSON.stringify(newAdjustment);
    console.log(adjustment);

    const response = await httpInventoryService.post<Adjustment>(
      `/adjustment`,
      newAdjustment,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to create adjustment'); // Throw an error for non-200 status codes
    }
  } catch (error: any) {
    console.error('Error creating adjustments:', error?.response?.data);
    return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
  }
};

const update = async (updatedAdjustment: Adjustment) => {
  try {
    const adjustment = JSON.stringify(updatedAdjustment);
    console.log(adjustment);

    const response = await httpInventoryService.put<Adjustment>(
      `/adjustment`,
      updatedAdjustment,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 202) {
      return { success: true };
    } else {
      throw new Error('Failed to update an adjustment'); // Throw an error for non-200 status codes
    }
  } catch (error: any) {
    console.error('Error updating adjustment:', error?.response?.data);
    return { success: false, error: error?.response?.data || 'Unknown error' }; // Return error message
  }
};

export const InventoryService = {
  getAdjustments,
  getAdjustmentById,
  create,
  update
};