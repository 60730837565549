import { WORKORDER_LABOUR_OTFACTOR_DOUBLETIME, WORKORDER_LABOUR_OTFACTOR_REGULAR, WORKORDER_LABOUR_OTFACTOR_TIMEHALF} from "./constants";

export const mapOTFactorDesc = (val : number) => {
    if(val === WORKORDER_LABOUR_OTFACTOR_TIMEHALF.otFactorValue){
      return WORKORDER_LABOUR_OTFACTOR_TIMEHALF.otFactorLabel;
    }
    if(val === WORKORDER_LABOUR_OTFACTOR_DOUBLETIME.otFactorValue){
      return WORKORDER_LABOUR_OTFACTOR_DOUBLETIME.otFactorLabel;
    }
    return WORKORDER_LABOUR_OTFACTOR_REGULAR.otFactorLabel;
  }