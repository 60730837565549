import {
  GoABlock,
  GoAButton,
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoAPagination,
  GoASpacer,
  GoATable,
  GoATableSortHeader,
} from "@abgov/react-components";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageLoader from "../../components/PageLoader";
import moment from "moment";
import { ForObjectType } from "../../model/enums/Constants";
import { CommonLookup } from "../../model/Common";
import { InventoryService } from "../../services/InventoryService";
import { Adjustment, Adjustments } from "../../model/Adjustments";
import { FunctionComponent, useState } from "react";
import SearchInput from "../../components/searchInput";
import { CustomFmF } from "../../components/datePicker";
import { getDataFromSessionStorage } from "../../utils/SessionStorageUtils";
import { loadCommonLookups } from "../../states/Common.state";
import { hasResourceRole } from "../../utils/tokens";
import { permissions } from "../../common/constants";
import { useAuth } from "react-oidc-context";


interface ICatalogProps { }

const ConsumableAdjustment: FunctionComponent<ICatalogProps> = (
  props
) => {
  // Navigation
  const navigate = useNavigate();
  //Loader
  const [loading, setIsLoading] = useState(true);
  //Search
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [adjustments, setAdjustments] = useState<Adjustments>(new Adjustments());
  const [adjustmentReasonOptions, setAdjustmentReasonOptions] = useState<any>([]);
  const [page, setPage] = useState(1);

  //count per page
  const [perPage, setPerPage] = useState(25);

  const [totalCount, setTotalCount] = useState(0);

  //Sorting
  const [sortCol, setSortCol] = useState("createdDate");
  const [sortDir, setSortDir] = useState(0);

  const auth = useAuth();

  React.useEffect(() => {

    const getAdjustments = async () => {

      try {
        setIsLoading(true);
        const response = await InventoryService.getAdjustments(searchValue, page, perPage, sortCol, sortDir === 1 ? "ASC" : "DESC", startDate ? startDate : moment('1900-01-01').toDate(), endDate ? endDate : moment('2900-01-01').toDate());
        if (response)
          setAdjustments(p => {
            setTotalCount(response?.data?.pageInfo?.total ?? 0)
            return response.data;
          });


        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching adjustments:', error);
        setIsLoading(false);
      }
    };

    getAdjustments();
  }, [startDate, endDate, searchValue, page, perPage, sortCol, sortDir, setAdjustments, setTotalCount]);

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = getDataFromSessionStorage(ForObjectType.ADJUSTMENTS);
        if (!response) {
          loadCommonLookups();
        }
        const adjustmentReason = await response.find((x: CommonLookup) => x.name === 'AdjustmentReason');

        if (adjustmentReason) {
          setAdjustmentReasonOptions(adjustmentReason.elements);
        };

      } catch (error) {
        console.error("Error fetching AdjustmentReason lookup:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  function searchAdjustmsnts(searchParamValue: string) {
    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setPage(1);
      setSearchValue(searchParamValue);
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
    }
  }

  function sortData(sortBy: string, sortDir: number) {
    setAdjustments(new Adjustments());
    setSortCol(sortBy);
    setSortDir(sortDir);
  }

  function changePerPage(name: any, newPage: any) {
    if (newPage !== page) {
      setPerPage(newPage);
    }
  }

  //Pagination change page
  function changePage(newPage: any) {
    if (newPage !== perPage) {
      setPerPage(perPage);
      setPage(newPage);
    }
  }

  const handleStartDateChange = (value: any) => {
    setStartDate(value.target.value); // Update startDate state with the new value
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value.target.value); // Update startDate state with the new value
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const canCreateAdjustment = hasResourceRole(
    String(process.env.REACT_APP_IMIS_CLIENT),
    permissions.IMS_ADJUSTMENT_WRITE,
    auth.user?.access_token ?? ""
  );


  return (
    <>
      <PageLoader visible={loading} />
      <div className="content-padding">
        <div className="left width100">
          <div className="left width-auto">
            <GoAButton type="tertiary" trailingIcon="arrow-back" onClick={() => window.location.pathname = '/home'}>Home</GoAButton>
            <GoAButton type="tertiary" onClick={() => navigate(``)}>Consumable adjustments</GoAButton>
          </div>
        </div>

        <div className="left width100">
          <div className="left width-auto">
            <PageHeader title="Consumable adjustments" />
          </div>
          {canCreateAdjustment &&
            <div className="right width50 paddingLeft20px input-search align-right">
              <GoAButton type="primary" trailingIcon="add" onClick={() => navigate(`/adjustmentNew`)}>Create consumable adjustment</GoAButton>
            </div>
          }
        </div>

        <div className="command-bar">
          <div className="left width-auto header-button form-row">
            <div className="item-space">
              <GoAFormItem label="From">
                <CustomFmF icon={startDate ? 'close' : 'calendar'} format="yyyy-MM-dd" value={startDate} onChange={(value: Date | null) => handleStartDateChange(value)} onBlur={(val: any) => { }} />
              </GoAFormItem>
            </div>
            <div>
              <GoAFormItem label="To">
                <CustomFmF icon={endDate ? 'close' : 'calendar'} format="yyyy-MM-dd" value={endDate} onChange={(value: Date | null) => handleEndDateChange(value)} onBlur={(val: any) => { }} />
              </GoAFormItem>
            </div>
          </div>
          <div className="right width50 paddingLeft20px input-search-adjustments">
            <SearchInput label="" search={searchAdjustmsnts} clearSearch={clearSearch} placeholder="Search by adjustment#, fire#, location, IMIS ID, part#, item name"></SearchInput>
          </div>
        </div>

        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="adjustmentNo">
                    Adjustment #
                  </GoATableSortHeader >
                </th>
                <th>
                  <GoATableSortHeader name="fireNo">
                    Fire #
                  </GoATableSortHeader ></th>
                <th>
                  <GoATableSortHeader name="locationName">
                    Location
                  </GoATableSortHeader >
                </th>
                <th><GoATableSortHeader name="reason">
                  Reason
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="comments">
                  Comments
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="createdDate">
                  Created on
                </GoATableSortHeader ></th>
                <th><GoATableSortHeader name="createdBy">
                  Created by
                </GoATableSortHeader ></th>
              </tr>
            </thead>
            <tbody>
              {adjustments?.adjustments?.map((record: Adjustment, index: any) => (
                <tr key={record.id}>
                  <td>
                    <GoAButton type="tertiary" onClick={() => navigate(`/adjustmentView/${record.adjustmentNo}/${record.id}`)}> {record.adjustmentNo}</GoAButton>
                  </td>
                  <td>{record.fireNo}</td>
                  <td>{record.locationName}</td>
                  <td><div className="bigtxt-div">{adjustmentReasonOptions?.find((u: any) => u.id === record.reason)?.value}</div></td>
                  <td><div className="bigtxt-div">
                    {record?.comments?.map((comment, index) => (
                      <div key={index}>{comment.text}</div>
                    ))}
                  </div>
                  </td>
                  <td>{moment(record.auditTrail.createdDate).format("yyyy-MM-DD")}</td>
                  <td>{record.auditTrail.createdBy}</td>
                </tr>
              ))}
            </tbody>
          </GoATable>
          <div className={totalCount > 0 ? "visible pagination pagination-div" : "not-visible pagination"}>
          <GoABlock alignment="center">
            <GoABlock mb="m" alignment="center" gap="m">
              <span style={{ whiteSpace: "nowrap" }}>Show</span>
              <div className="dropdown-list">
                <GoADropdown name="selPerPage" onChange={changePerPage} value="25" width="8ch">
                  <GoADropdownItem value="25"></GoADropdownItem>
                  <GoADropdownItem value="50"></GoADropdownItem>
                  <GoADropdownItem value="100"></GoADropdownItem>
                </GoADropdown>
              </div>
              <span style={{ whiteSpace: "nowrap" }}>
                of {totalCount} items
              </span>
            </GoABlock>
            <GoASpacer hSpacing="fill" />
            <GoAPagination
              itemCount={totalCount || 10}
              perPageCount={perPage}
              pageNumber={page}
              onChange={changePage}
            />
          </GoABlock>
        </div>
        </div>
      </div>
    </>
  );
};

export default ConsumableAdjustment;
