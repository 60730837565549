import {
  GoAHeroBanner,
} from "@abgov/react-components";
import * as React from "react";

interface INotFoundProps {}

const Unauthorized: React.FunctionComponent<INotFoundProps> = (props) => {
  return (
    <>
      <GoAHeroBanner heading="You don’t have permission to view this page. Contact the IMIS Administrator."></GoAHeroBanner>
    </>
  );
};

export default Unauthorized;
