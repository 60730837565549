import { PageInfo } from "./Base";

export class Catalogs{
    catalogs: Catalog[] = [];
    pageInfo:PageInfo | undefined;
}

export class Catalog {
    id: string;
    name: string;
    description: string;
    catalogType: number;
    status: number;
    isChecked: boolean=false;
    items: any[]; // You can replace 'any[]' with a more specific type if needed
    totalItems: number;
    auditTrail: {
        createdBy: string;
        createdDate: string;
        isDeleted: boolean;
        lastUpdatedBy: string;
        lastUpdatedDate: string;
    };

    constructor(from?: Partial<Catalog>) {
        this.id = from?.id||'';
        this.name = from?.name||'';
        this.description = from?.description||'';
        this.catalogType = from?.catalogType||0;
        this.status = from?.status||0;
        this.isChecked = from?.isChecked||false;
        this.items = from?.items||[];
        this.totalItems = from?.totalItems||0;
        this.auditTrail = {
            createdBy: from?.auditTrail?.createdBy||'',
            createdDate:from?.auditTrail?.createdDate||'',
            isDeleted: from?.auditTrail?.isDeleted||false,
            lastUpdatedBy: from?.auditTrail?.lastUpdatedBy||'',
            lastUpdatedDate: from?.auditTrail?.lastUpdatedDate||''
        };
    }
}

export class CatalogToCreate {
    name: string;
    description: string;
    catalogType: number;
    status: number;
  detail: CatalogToCreate | undefined;

    constructor(from?: Partial<Catalog>) {
        this.name = from?.name||'';
        this.description = from?.description||'';
        this.catalogType = from?.catalogType||0;
        this.status = from?.status||0;
    }
}

 

 
