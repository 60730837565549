import axios from "axios";

export const httpCatalogService = axios.create({
  baseURL: process.env.REACT_APP_API_CATALOG_BASE_URL,
  headers: {
    'Content-Type':'application/javascript',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});

export const httpCommonService = axios.create({
  baseURL: process.env.REACT_APP_API_COMMON_BASE_URL,
  headers: {
    'Content-Type':'application/javascript',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});
 
export const httpInventoryService = axios.create({
  baseURL: process.env.REACT_APP_API_INVENTORY_BASE_URL,
  headers: {
    'Content-Type':'application/javascript',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});

export const httpLocationService = axios.create({
  baseURL: process.env.REACT_APP_API_LOCATION_BASE_URL,
  headers: {
    'Content-Type':'application/javascript',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});
 
export const httpWorkOrderService = axios.create({
  baseURL: process.env.REACT_APP_API_WORKORDER_BASE_URL,
  headers: {
    'Content-Type':'application/javascript',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});

export const httpOracleQueryService = axios.create({
  baseURL: process.env.REACT_APP_API_ORACLEQUERY_BASE_URL,
  headers: {
    'Content-Type':'application/json',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});

export const httpPdfService = axios.create({
  baseURL: process.env.REACT_APP_API_PDF_BASE_URL,
  headers: {
    'Content-Type':'application/javascript',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});

export const httpAviationService = axios.create({
  baseURL: process.env.REACT_APP_API_AVIATION_BASE_URL,
  headers: {
    'Content-Type':'application/javascript'
  },
});

export const httpWildfireFinanceService = axios.create({
  baseURL: process.env.REACT_APP_API_WILDFIRE_FINANCE_BASE_URL,
  headers: {
    'Content-Type':'application/javascript',
    'Authorization' : `Bearer ${localStorage.getItem("accessToken")}`
  },
});



